<h1 id="guilds-header">Guilds</h1>
<div class="wrapper">
  <div class="wrapper-content-section">
    <div class="header-section">
      <div class="search-bar">
        <sl-search-guilds (searchResultsBeingShown)="searchResultsBeingShown($event)" (searchingForGuilds)="searchingForGuilds($event)" (handleSelectedGuild)="handleSelectedGuild($event)" (toggleCheckbox)="handleCheckboxState($event)"
          [showAllGuilds]="checkboxToggled"></sl-search-guilds>
        <button class="create-guilds-btn" *ngIf="!isCreatingGuild" nz-button nzType="primary" nzShape="circle" (click)="createGuild()">
          <i class="create-guilds-btn-icon" nz-icon nzType="plus" title="Create a new guild"></i>
        </button>
      </div>
    </div>

    <div class="sidebar-section">
      <sl-my-guilds [myGuilds]="myGuilds" [currentGuild]="currentGuild" [currentUser]="currentUser" [radioValue]="!isCreatingGuild ? currentGuildId : null" (handleSelectedGuild)="handleSelectedGuild($event)"></sl-my-guilds>
    </div>

    <div class="alert-no-guilds-to-display-container" *ngIf="!searchResultGuilds?.length && isHomeGuildRoute && !isSearchingForGuilds && !isLoading">
      <nz-alert id="alert-no-guild-to-display" nzType="info" nzShowIcon [nzMessage]="heading" [nzDescription]="descriptionTpl">
        <ng-template #descriptionTpl>
          <br />

          <span class="alert-body">
            No more guilds to show at the moment. Perhaps you can
            <strong><a nzType="link" [routerLink]="['/guilds/create']">create a guild</a></strong> and invite others, or try browsing "All" guilds
            <br />
            <br />
          </span>
        </ng-template>
        <ng-template #heading>
          <span class="alert-heading">No guilds to display...yet!</span>
        </ng-template>
      </nz-alert>
    </div>

    <div class="loading-spinner" *ngIf="(isHomeGuildRoute && isSearchingForGuilds) || (isLoading && !isCreatingGuild)">
      <nz-spin [nzSize]="'large'" nzTip="Loading Guilds...">
      </nz-spin>
    </div>

    <div class="search-section" *ngIf="isHomeGuildRoute && !isCreatingGuild">
      <sl-search-results [searchResultGuilds]="searchResultGuilds" [isSearching]="isSearchingForGuilds" (handleSelectedGuildCard)="handleSelectedGuild($event)"></sl-search-results>
    </div>

    <div class="homepage-section" *ngIf="!isHomeGuildRoute && !isCreatingGuild && !isLoading && !isSearchingForGuilds">
      <sl-guild-home class="guild-home-component" [guild]="currentGuild" [isAdmin]="isAdmin" (leftGuild)="userLeftGuild($event)" (selectGuild)="handleSelectedMyGuildsIcon($event)" (handleJoiningGuild)="handleJoiningGuild($event)" (handleLeavingGuild)="handleLeavingGuild($event)"
        (handlePermissionToConductSearch)="handlePermissionToConductGuildSearch($event)"></sl-guild-home>
    </div>

    <div class="create-guild-section" *ngIf="!isHomeGuildRoute && isCreatingGuild">
      <sl-create-guild class="create-guild-section" (newlyCreatedGuild)="handleCreatedGuild($event)" (cancelledGuildCreation)="handleCancelledGuildCreation($event)"></sl-create-guild>
    </div>
  </div>
</div>