import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DefaultUrlSerializer, UrlSegmentGroup, UrlSerializer, UrlTree } from '@angular/router';

import { HttpErrorInterceptor } from './services/http-error.interceptor';

// services
import { AccessGoalPageGuard } from './services/access-goal-page.guard';
import { AccessHomePageGuard } from './services/access-home-page.guard';
import { AccountDetailsService } from './services/account-details.service';
import { AdminGuardServiceGuard } from './components/admin/admin-guard-service.guard';
import { AuthGuard } from './services/auth.guard';
import { AuthService } from './services/auth.service';
import { EmailMessageService } from './services/email-message.service';
import { EmploymentExperienceService } from './services/employment-experience.service';
import { GoalService } from './services/goal.service';
import { GoalServiceData } from './services/goal.service.data';
import { GtmService } from './services/gtm.service';
import { HasGoalGuard } from './services/has-goal.guard';
import { InviteService } from './services/invite.service';
import { LearningService } from './services/learning.service';
import { MockAuthService } from './services/mock.auth.service';
import { OnboardingService } from './services/onboarding.service';
import { PlaylistService } from './services/playlist.service';
import { ProvidersService } from './services/providers.service';
import { RecommendationService } from './services/recommendation.service';
import { RegisterService } from './services/register.service';
import { RoutingStateService } from './services/routing-state.service';
import { SignatureService } from './services/signature.service';
import { SkillDuplicatesService } from './services/skill-duplicates.service';
import { SkillService } from './services/skill.service';
import { SkillsReportService } from './services/skillsreport.service';
import { SuggestionsService } from './services/suggestions.service';
import { TopSkillService } from './services/top-skill.service';
import { TrackService } from './services/track.service';
import { TrackServiceData } from './services/track.service.data';
import { UserActivitiesService } from './services/user-activities.service';
import { UserActivitiesServiceData } from './services/user-activities.service.data';

// components
import { ActionCardComponent } from './components/browse-recommendations/recommendation-goal/action-card/action-card.component';
import { ActionsComponent } from './components/admin/actions/actions.component';
import { AdminComponent } from './components/admin/admin.component';
import { AllUserTopSkillsComponent } from './components/admin/all-user-top-skills/all-user-top-skills.component';
import { AppComponent } from './app.component';
import { AppDetailsComponent } from './components/app-details/app-details.component';
import { ApproveTracksComponent } from './components/admin/approve-tracks/approve-tracks.component';
import { AuthCallbackComponent } from './components/account/auth-callback/auth-callback.component';
import { BrowseRecommendationsComponent } from './components/browse-recommendations/browse-recommendations.component';
import { DisplayUserPlaylistsComponent } from './components/admin/playlists/display-user-playlists/display-user-playlists/display-user-playlists.component';
import { EmailMessagesComponent } from './components/admin/email-messages/email-messages.component';
import { EmailMessageTabsComponent } from './components/admin/email-messages/email-message-tabs/email-message-tabs.component';
import { EmailMessageTemplatesComponent } from './components/admin/email-messages/email-message-tabs/email-message-templates/email-message-templates.component';
import { EmailMetricsComponent } from './components/admin/email-messages/email-metrics/email-metrics.component';
import { EmploymentExperienceComponent } from './components/profile-v2/employment-experience/employment-experience.component';
import { GoalActionsComponent } from './components/profile-v2/profile-overview/goal-actions/goal-actions.component';
import { GoalComponent } from './components/goal/goal.component';
import { GoalSignatureSelectorComponent } from './components/goal-signature-selector/goal-signature-selector.component';
import { GoalSkillsComponent } from './components/goal/goal-track/goal-skills/goal-skills.component';
import { GoalCoreSkillsComponent } from './components/goal/goal-track/goal-core-skills/goal-core-skills.component';
import { GoalTableComponent } from './components/profile-v2/profile-overview/goal-table/goal-table.component';
import { GoalTrackComponent } from './components/goal/goal-track/goal-track.component';
import { GuildsAdminComponent } from './components/admin/guilds-admin/guilds-admin.component';
import { HelpInfoComponent } from './components/help-info/help-info.component';
import { InvitationCodeComponent } from './components/admin/invitation-code/invitation-code.component';
import { KeywordInfoComponent } from './components/keyword-info/keyword-info.component';
import { LandingV2Component } from './components/landing-v2/landing-v2.component';
import { ManualUserActivityComponent } from './components/profile-v2/profile-activities-table/manual-user-activity/manual-user-activity.component';
import { MetricsComponent } from './components/admin/metrics/metrics.component';
import { NameplateComponent } from './components/profile-v2/nameplate/nameplate.component';
import { NavbarSideComponent } from './components/navbar-side/navbar-side.component';
import { NotificationBadgeComponent } from './components/navbar-side/notification-badge/notification-badge.component';
import { NotificationsComponent } from './components/admin/notifications/notifications.component';
import { OnboardingComponent } from './components/profile-v2/onboarding/onboarding.component';
import { PlaylistCardComponent } from './components/browse-recommendations/recommendation-goal/playlist-card/playlist-card.component';
import { PlaylistsComponent } from './components/admin/playlists/playlists.component';
import { ProfileActivitiesComponent } from './components/profile-v2/profile-activities/profile-activities.component';
import { ProfileActivitiesTableComponent } from './components/profile-v2/profile-activities-table/profile-activities-table.component';
import { ProfileActivityActionsComponent } from './components/profile-v2/profile-activities-table/profile-activity-actions/profile-activity-actions.component';
import { ProfileOverviewComponent } from './components/profile-v2/profile-overview/profile-overview.component';
import { ProfilePlaylistsComponent } from './components/profile-v2/profile-playlists/profile-playlists.component';
import { ProfileV2Component } from './components/profile-v2/profile-v2.component';
import { ProviderCardComponent } from './components/providers/provider-card/provider-card.component';
import { ProvidersComponent } from './components/providers/providers.component';
import { PublicProfileComponent } from './components/public-profile/public-profile.component';
import { RecommendationGoalComponent } from './components/browse-recommendations/recommendation-goal/recommendation-goal.component';
import { RecommendationPageComponent } from './components/browse-recommendations/recommendation-page/recommendation-page.component';
import { RegisterComponent } from './components/register/register.component';
import { SeedDatabaseComponent } from './components/admin/seed-database/seed-database.component';
import { SkillCard2Component } from './components/profile-v2/top-skills/skill-card-v2/skill-card2.component';
import { SkillduplicatesComponent } from './components/admin/skillduplicates/skillduplicates.component';
import { SuggestionsComponent } from './components/suggestions/suggestions.component';
import { TermsComponent } from './components/terms/terms.component';
import { TopSkillsComponent } from './components/profile-v2/top-skills/top-skills.component';
import { TrackHistoryComponent } from './components/admin/track-history/track-history.component';
import { UncategorisedSkillsComponent } from './components/admin/uncategorised-skills/uncategorised-skills.component';
import { UnassignedActivitiesComponent } from './components/profile-v2/profile-overview/unassigned-activities/unassigned-activities.component';
import { UpdateDataComponent } from './components/update-data/update-data.component';
import { UserActivitiesComponent } from './components/admin/user-activities/user-activities.component';
// pipes
import { FormatGoalDurationPipe } from './components/profile-v2/format-goal-duration.pipe';
import { SearchActivitiesPipe } from './components/profile-v2/search-activities.pipe';
import { SearchRecommendationPipe } from './components/browse-recommendations/search-recommendation.pipe';
// directives
import { ClickOutsideDirective } from './directives/click-outside.directive';
// modules
import { AccountModule } from './modules-account/account.module';
import { GenericComponentsModule } from './generic-components/generic-components.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxGpAutocompleteModule } from "@angular-magic/ngx-gp-autocomplete";
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { GuildsModule } from './modules-guilds/guilds.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxPicaModule } from '@digitalascetic/ngx-pica';
import { LocalStorageDirective } from 'ngx-localstorage';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzConfig, NZ_CONFIG } from 'ng-zorro-antd/core/config';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { ReactiveFormsModule } from '@angular/forms';
import { RollbarService, rollbarFactory, RollbarErrorHandler } from './rollbar';
import { RouterModule, Routes } from '@angular/router';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

//Froala Editor
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/image_manager.min.js';
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/save.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/line_height.min.js';
import 'froala-editor/js/plugins/char_counter.min.js';

import en from '@angular/common/locales/en';
import { CareerDetailsService } from './services/career-details.service';
import { StripeReconciliationComponent } from './components/admin/stripe-reconciliation/stripe-reconciliation.component';
import { Loader } from '@googlemaps/js-api-loader';
import { PricingComponent } from './components/pricing/pricing.component';
registerLocaleData(en);

const ngZorroConfig: NzConfig = {
  modal: { nzMaskClosable: false },
};

// See: https://stackoverflow.com/questions/51985973/in-angular-6-how-make-case-insensitive-url-pattern
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    const urlTree = super.parse(url);

    if (url.includes('/from/') && url.includes('/UPL:', 12) || url.includes('/PL:', 12) || url.includes('activity')) {
      return urlTree;
    }

    this.lowerCaseSegments(urlTree.root);

    return urlTree;
  }

  private lowerCaseSegments(urlSegmentGroup: UrlSegmentGroup) {
    if (urlSegmentGroup.hasChildren()) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Object.entries(urlSegmentGroup.children).forEach(([key, value]) => this.lowerCaseSegments(value));
    }

    urlSegmentGroup.segments.forEach((segment) => (segment.path = segment.path.toLowerCase()));
  }
}

const routes: Routes = [
  {
    path: 'profile',
    component: ProfileV2Component,
    canActivate: [AuthGuard, HasGoalGuard],
    children: [
      {
        path: 'overview',
        component: ProfileOverviewComponent,
      },
      { path: 'activities', component: ProfileActivitiesTableComponent },
      { path: 'providers', component: ProvidersComponent },
      { path: 'playlists', component: ProfilePlaylistsComponent },
    ],
  },
  { path: 'profile/:user', component: PublicProfileComponent },
  { path: 'profile/:user/:guildId/:permission/guild-invite', component: PublicProfileComponent },
  { path: 'landing', component: LandingV2Component },
  { path: 'register', component: RegisterComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'recommended/activity/:activityId/:goalId/:industry/:profession/:specialisation/from/:userUrl', component: RecommendationPageComponent, canActivate: [AuthGuard, HasGoalGuard] },
  { path: 'recommended/activity/:activityId/:goalId/:industry/:profession/:specialisation', component: RecommendationPageComponent, canActivate: [AuthGuard, HasGoalGuard] },
  { path: 'recommended/activity/:activityId/:goalId', component: RecommendationPageComponent, canActivate: [AuthGuard, HasGoalGuard] },
  { path: 'recommended/userplaylist/:userPlaylistId/from/:userUrl/:inviteCode', component: RecommendationPageComponent },
  { path: 'recommended/playlist/:playlistId/from/:userUrl/:inviteCode', component: RecommendationPageComponent },
  { path: 'goal', component: GoalComponent, canActivate: [AuthGuard, AccessGoalPageGuard] },
  { path: 'recommendations', component: BrowseRecommendationsComponent, canActivate: [AuthGuard, HasGoalGuard] },
  { path: 'admin', canActivate: [AdminGuardServiceGuard], component: AdminComponent },
  { path: 'auth-callback', component: AuthCallbackComponent },
  { path: 'silent-refresh.html', redirectTo: 'assets/silent-refresh.html' },
  { path: '', component: LandingV2Component },
  { path: '**', redirectTo: 'profile/overview' },
];
@NgModule({
  declarations: [
    ActionCardComponent,
    ActionsComponent,
    AdminComponent,
    AllUserTopSkillsComponent,
    AppComponent,
    AppDetailsComponent,
    ApproveTracksComponent,
    AuthCallbackComponent,
    BrowseRecommendationsComponent,
    ClickOutsideDirective,
    DisplayUserPlaylistsComponent,
    EmailMessagesComponent,
    EmailMessageTabsComponent,
    EmailMessageTemplatesComponent,
    EmailMetricsComponent,
    EmploymentExperienceComponent,
    FormatGoalDurationPipe,
    GoalActionsComponent,
    GoalComponent,
    GoalSignatureSelectorComponent,
    GoalSkillsComponent,
    GoalCoreSkillsComponent,
    GoalTableComponent,
    GoalTrackComponent,
    GuildsAdminComponent,
    HelpInfoComponent,
    InvitationCodeComponent,
    KeywordInfoComponent,
    LandingV2Component,
    ManualUserActivityComponent,
    MetricsComponent,
    NameplateComponent,
    NavbarSideComponent,
    NotificationBadgeComponent,
    NotificationsComponent,
    OnboardingComponent,
    PlaylistCardComponent,
    PlaylistsComponent,
    ProfileActivitiesComponent,
    ProfileActivitiesTableComponent,
    ProfileActivityActionsComponent,
    ProfileOverviewComponent,
    ProfilePlaylistsComponent,
    ProfileV2Component,
    ProviderCardComponent,
    ProvidersComponent,
    PublicProfileComponent,
    RecommendationGoalComponent,
    RecommendationPageComponent,
    RegisterComponent,
    SearchActivitiesPipe,
    SearchRecommendationPipe,
    SeedDatabaseComponent,
    SkillCard2Component,
    SkillduplicatesComponent,
    SuggestionsComponent,
    TermsComponent,
    TopSkillsComponent,
    TrackHistoryComponent,
    UnassignedActivitiesComponent,
    UncategorisedSkillsComponent,
    UpdateDataComponent,
    UserActivitiesComponent,
    StripeReconciliationComponent,
  ],
  imports: [
    AccountModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    DragDropModule,
    FormsModule,
    GenericComponentsModule,
    GuildsModule,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-P58XPRB',
      gtm_auth: 'fDkQmx0oWCYZMdCN5tfWKg',
      gtm_preview: 'env-2',
    }),
    HttpClientModule,
    InfiniteScrollModule,
    LocalStorageDirective,
    NgxChartsModule,
    NgxGpAutocompleteModule,
    NgxPicaModule,
    NzAffixModule,
    NzAlertModule,
    NzAutocompleteModule,
    NzBadgeModule,
    NzButtonModule,
    NzCardModule,
    NzCheckboxModule,
    NzCollapseModule,
    NzDatePickerModule,
    NzDividerModule,
    NzDrawerModule,
    NzDropDownModule,
    NzFormModule,
    NzGridModule,
    NzIconModule,
    NzImageModule,
    NzInputModule,
    NzInputNumberModule,
    NzLayoutModule,
    NzMenuModule,
    NzMessageModule,
    NzNotificationModule,
    NzModalModule,
    NzPaginationModule,
    NzPopconfirmModule,
    NzPopoverModule,
    NzProgressModule,
    NzRadioModule,
    NzResultModule,
    NzSelectModule,
    NzSkeletonModule,
    NzSliderModule,
    NzSpaceModule,
    NzSpinModule,
    NzStepsModule,
    NzSwitchModule,
    NzTableModule,
    NzTabsModule,
    NzTagModule,
    NzTimelineModule,
    NzToolTipModule,
    NzTypographyModule,
    NzUploadModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, { enableTracing: true }),
    CommonModule,
    NgxChartsModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer,
    },
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: 'AIzaSyALCilOMVdUmxwqAaR1js-PN9qmLrbJG5Q&libraries=places&language=en',
        libraries: ['places']
      })
    },
    AccessGoalPageGuard,
    AccessHomePageGuard,
    AccountDetailsService,
    AdminGuardServiceGuard,
    AuthGuard,
    AuthService,
    CareerDetailsService,
    EmailMessageService,
    EmploymentExperienceService,
    GoalService,
    GoalServiceData,
    GtmService,
    HasGoalGuard,
    InviteService,
    LearningService,
    MockAuthService,
    OnboardingService,
    PlaylistService,
    ProvidersService,
    RecommendationService,
    RegisterService,
    RoutingStateService,
    SignatureService,
    SkillService,
    SkillsReportService,
    SkillDuplicatesService,
    SuggestionsService,
    TopSkillService,
    TrackService,
    TrackServiceData,
    UserActivitiesService,
    UserActivitiesServiceData,
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_CONFIG, useValue: ngZorroConfig },
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    // { provide: 'googleTagManagerCSPNonce', useValue: 'CSP-NONCE' },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: RollbarService, useFactory: rollbarFactory },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    // console.log('*** APP STARTING');
  }
}
