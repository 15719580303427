import { Component, Input, OnChanges, OnInit, Output, SimpleChanges, EventEmitter, } from '@angular/core';
import { Router } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd/message';
import { IMetricLineChart } from 'src/app/models/metric-name-value.model';
import { IUserActivity } from 'src/app/models/user-activity.model';

// models
import { ESubscriptionStatus, IUserProfilePublic } from 'src/app/models/user-profile.model';

// services
import { AccountDetailsService } from 'src/app/services/account-details.service';
import { MetricService } from 'src/app/services/metric.service';
import { PaymentService } from 'src/app/services/payment.service';
import { EMetricViewType } from 'src/app/types/metric-view-type';

@Component({
  selector: 'sl-nameplate',
  templateUrl: './nameplate.component.html',
  styleUrls: ['./nameplate.component.less'],
})
export class NameplateComponent implements OnInit, OnChanges {
  @Output() inviteUserToGuildEmitter = new EventEmitter<string>();
  @Input() userProfilePublic?: IUserProfilePublic;  // Pass in the public profile if you want the component to be in public profile mode, otherwise, it will go and get the profile of the current user.
  @Input() isPublicProfileMode = false;
  @Input() isInvitingUserToGuild = false;
  @Input() hasPermissionToInviteUserToGuild = false;
  @Input() refreshUserActivityMetric = false;
  @Input() hasInvitedUserToGuild = false;
  fullName!: string;
  userLocation!: string;
  subscriptionStatus!: ESubscriptionStatus;
  subscriptionStatusMessage!: string;
  view: number[] = [200, 80];
  colourScheme = 'ocean';
  completedUserActivities!: IUserActivity[];
  completedUserActivitiesMetric!: IMetricLineChart;
  metricViewType!: EMetricViewType;
  viewType = EMetricViewType;
  animations = true;
  componentInitialised = false; // used to suppress the first ngOnChanges before the component is initialised.

  //sparkline data points
  monthlyBinAmount = 3;
  weeklyBinAmount = 12;
  yearlyBinAmount = 12;

  constructor(private accountDetailsService: AccountDetailsService, private paymentService: PaymentService, public router: Router, private message: NzMessageService, private metricService: MetricService) { }

  ngOnInit(): void {
    this.getUserProfile();
    this.getSubscriptionStatus();
    this.componentInitialised = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.componentInitialised && changes.refreshUserActivityMetric) {
      this.refreshUserActivityMetricChart();
    }
  }

  inviteUserToGuild() {
    this.inviteUserToGuildEmitter.emit(this.userProfilePublic?.publicUrl);
  }

  getSubscriptionStatus() {
    if (this.isPublicProfileMode) {
      return;
    }

    this.paymentService.getSubscriptionStatus().subscribe({
      next: (res) => {
        this.subscriptionStatus = res;
        this.setSubscriptionStatusMessage();
      },
      error: (err) => {
        this.message.error('Failed to get subscription status');
        console.error(`Failed to get subscription status: ${err.message}`);
      },
    });
  }

  editAccount() {
    const route = this.router.url;
    this.router.navigate(['/account', { from: route }]);
  }

  toggleMetricViewType() {
    if (this.metricViewType === EMetricViewType.Weekly) {
      // If currently Weekly, switch to Monthly
      this.getCompletedActivitiesMetric(EMetricViewType.Monthly, this.monthlyBinAmount);
    } else if (this.metricViewType === EMetricViewType.Monthly) {
      // If currently Monthly, switch to Yearly
      this.getCompletedActivitiesMetric(EMetricViewType.Yearly, this.yearlyBinAmount);
    } else {
      // If currently Yearly, switch to Weekly
      this.getCompletedActivitiesMetric(EMetricViewType.Weekly, this.weeklyBinAmount);
    }
  }

  refreshUserActivityMetricChart() {
    if (this.metricViewType === EMetricViewType.Monthly) {
      this.getCompletedActivitiesMetric(EMetricViewType.Monthly, this.monthlyBinAmount);
    } else if (this.metricViewType === EMetricViewType.Yearly) {
      this.getCompletedActivitiesMetric(EMetricViewType.Yearly, this.yearlyBinAmount);
    } else {
      this.getCompletedActivitiesMetric(EMetricViewType.Weekly, this.weeklyBinAmount);
    }
    this.refreshUserActivityMetric = false;
  }

  private getUserProfile() {
    if (this.userProfilePublic) {
      this.setUserLocation(this.userProfilePublic);
      this.setUserName(this.userProfilePublic);
    } else {
      this.accountDetailsService.getUserProfileSubscription().subscribe({
        next: (profile: IUserProfilePublic) => {
          this.userProfilePublic = profile;
          this.setUserLocation(this.userProfilePublic);
          this.setUserName(this.userProfilePublic);
        },
        error: (err) => console.error(err)
      });
    }

    this.getCompletedActivitiesMetric(EMetricViewType.Weekly, this.weeklyBinAmount);
  }

  private setSubscriptionStatusMessage() {
    if (this.subscriptionStatus === ESubscriptionStatus.Subscribed) {
      this.subscriptionStatusMessage = 'SeaLadder Trailblazer Account';
    } else if (this.subscriptionStatus === ESubscriptionStatus.Free) {
      this.subscriptionStatusMessage = 'SeaLadder Freerider Account';
    } else if (this.subscriptionStatus === ESubscriptionStatus.Trial) {
      this.subscriptionStatusMessage = 'SeaLadder Testdrive Account';
    } else if (this.subscriptionStatus === ESubscriptionStatus.TrialExpired) {
      this.subscriptionStatusMessage = 'SeaLadder Expired Trial';
    } else if (this.subscriptionStatus === ESubscriptionStatus.Cancelled) {
      this.subscriptionStatusMessage = 'SeaLadder Defector Account';
    } else if (this.subscriptionStatus === ESubscriptionStatus.Unsubscribed) {
      this.subscriptionStatusMessage = 'SeaLadder Account Expired';
    } else {
      this.subscriptionStatusMessage = 'Account Status is in a Nebulus Condition';
      console.error('The sealadderSubscription status is not known when it should just be one of the enumerations. Currently set to: ' + this.subscriptionStatus + '.');
    }
  }

  private setUserName(userProfile: IUserProfilePublic | undefined) {
    const firstName = (userProfile && userProfile.name) ? userProfile.name : '';
    const surname = (userProfile && userProfile.surname) ? (firstName ? ' ' : '') + userProfile.surname : '';
    this.fullName = firstName + surname;
  }

  private setUserLocation(userProfile: IUserProfilePublic | undefined) {
    const suburb = !(userProfile?.addressSuburb) ? '' : userProfile.addressSuburb;
    const state = !(userProfile?.addressState) ? '' : userProfile.addressState;
    this.userLocation = suburb !== '' && state !== '' ? `${suburb} | ${state}` : `${suburb}${state}`;
  }

  private getCompletedActivitiesMetric(viewType: EMetricViewType, numberOfBins: number) {
    this.metricViewType = viewType;
    this.metricService.getCompletedUserActivitesMetric(viewType, numberOfBins).subscribe(cam => {
      this.completedUserActivitiesMetric = cam;
    });
  }
}
