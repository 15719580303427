import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IGuild } from '../../models/guild.model';

@Component({
  selector: 'sl-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.less']
})
export class SearchResultsComponent {
  @Output() handleSelectedGuildCard = new EventEmitter<IGuild>();
  @Input() searchResultGuilds!: IGuild[];
  @Input() isSearching = false;

  selectGuild(guild: IGuild) {
    this.handleSelectedGuildCard.emit(guild);
  }
}
