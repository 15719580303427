/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Output, EventEmitter, Input, ViewChild, ElementRef, OnChanges, OnDestroy, HostListener, AfterViewInit } from '@angular/core';
import { Component } from '@angular/core';
import * as validateUrl from 'valid-url';

// services
import { LocalStorageService } from 'ngx-localstorage';

// models
import { IPlaylist } from './../../../models/playlist.model';
import { IGuildExternalLink } from 'src/app/modules-guilds/models/guild-external-link.model';
import { ELinkType } from 'src/app/modules-guilds/types/guild-link-type.type';
import { EditMode } from '../../types/edit-mode.type';
import { LinksState } from 'src/app/models/links-state.model';

import { GuildLinkData, GuildService } from 'src/app/services/guild.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Subject, takeUntil } from 'rxjs';

export interface LinkProperties {
  type: ELinkType;
  name: string;
  url: string;
  thumbnail: string;
  icon: string;
}

interface SavedLinkProperties {
  url: string;
  linkText: string;
}

@Component({
  selector: 'sl-link-selector',
  templateUrl: './link-selector.component.html',
  styleUrls: ['./link-selector.component.less'],
})
export class LinkSelectorComponent implements OnChanges, OnDestroy, AfterViewInit {
  @ViewChild('linksContainer', { static: false }) linksContainer!: ElementRef;
  dynamicLimit: number = 40; // Default limit

  //The components that are using the link selector
  @Input() playlist!: IPlaylist;
  @Input() guildId!: number;

  @Output() addLinkEvent = new EventEmitter<IGuildExternalLink>();
  @Output() deleteLinkEvent = new EventEmitter<IGuildExternalLink>();
  @Output() editLinkEvent = new EventEmitter<IGuildExternalLink>();
  @Output() editLinkTextEvent = new EventEmitter<string>();

  @Input() currentMode!: EditMode;
  @Input() guildLinks: IGuildExternalLink[] = [];

  //Link controls
  @ViewChild('linkUrl') linkInputField!: ElementRef;
  @ViewChild('linkText') linkText!: ElementRef;

  public links: IGuildExternalLink[] = [];
  public linkToBeEdited!: IGuildExternalLink;
  public linkValue = '';
  public linkTextValue = '';
  public linkTypeValue!: ELinkType | undefined;
  public linkTypeImage!: string | undefined;
  public imageUrlInputValue = '';
  public linkType = ELinkType;
  public linksState: LinksState = { amount: 3, expanded: true } as LinksState;

  public isEditingLink = false;
  public isAddingLink = false;
  public hasAttemptedToAddIncompleteLink = false;
  public hasAttemptedToAddExistingLink = false;
  public hasAttemptedToAddInvalidLink = false;
  public urlTypeSelected = false;
  public isGettingFavicon = false;
  public cancelRequest = new Subject<void>();

  linkProperties: Map<ELinkType, LinkProperties> = new Map([
    [ELinkType.LinkedIn, { type: ELinkType.LinkedIn, name: 'LinkedIn', url: 'https://www.linkedin.com/', thumbnail: '/assets/img/link-thumbnails/linkedin.png', icon: 'fa-brands fa-linkedin' }],
    [ELinkType.WhatsApp, { type: ELinkType.WhatsApp, name: 'WhatsApp', url: 'https://www.whatsapp.com/', thumbnail: '/assets/img/link-thumbnails/whatsapp.png', icon: 'fa-brands fa-square-whatsapp' }],
    [ELinkType.Signal, { type: ELinkType.Signal, name: 'Signal', url: 'https://signal.org/', thumbnail: '/assets/img/link-thumbnails/signal.png', icon: 'fa-solid fa-comment' }],
    [ELinkType.Slack, { type: ELinkType.Slack, name: 'Slack', url: 'https://slack.com/intl/en-au/', thumbnail: '/assets/img/link-thumbnails/slack.png', icon: 'fa-brands fa-slack' }],
    [ELinkType.Discord, { type: ELinkType.Discord, name: 'Discord', url: 'https://discord.com/', thumbnail: '/assets/img/link-thumbnails/discord.png', icon: 'fa-brands fa-discord' }],
    [ELinkType.Website, { type: ELinkType.Website, name: 'Website', url: 'https://', thumbnail: '/assets/img/link-thumbnails/website.png', icon: 'fa-solid fa-display' }],
    [ELinkType.Meetup, { type: ELinkType.Meetup, name: 'Meetup', url: 'https://www.meetup.com/en-AU/', thumbnail: '/assets/img/link-thumbnails/meetup.png', icon: 'fa-brands fa-meetup' }],
    [ELinkType.Facebook, { type: ELinkType.Facebook, name: 'Facebook', url: 'https://www.facebook.com/', thumbnail: '/assets/img/link-thumbnails/facebook.png', icon: 'fa-brands fa-facebook-square' }],
    [ELinkType.Other, { type: ELinkType.Other, name: 'Other', url: '', thumbnail: '/assets/img/sealadder/sealadder-logo-96x96.png', icon: 'fa-solid fa-ellipsis' }],
  ]);

  lastSelectedLinkValues: Map<number, SavedLinkProperties> = new Map();

  constructor(private localStorageService: LocalStorageService, private guildService: GuildService, private message: NzMessageService) { }

  ngOnChanges(): void {
    if (!this.guildLinks) {
      this.links = [];
    } else {
      this.links = this.guildLinks;
    }
    this.getLinkState();
  }

  ngOnDestroy(): void {
    this.cancelRequest.next();
    this.cancelRequest.complete();
    this.lastSelectedLinkValues.clear();
  }

  ngAfterViewInit() {
    if (this.links.length) {
      this.adjustTextLength();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.adjustTextLength();
  }

  adjustTextLength() {
    const containerWidth = this.linksContainer.nativeElement.offsetWidth;
    this.dynamicLimit = Math.floor(containerWidth / 10); // Adjust this factor based on your design needs
  }

  setDefaultImage(event: Event) {
    (event.target as HTMLImageElement).src = '/assets/img/sealadder/sealadder-logo-96x96.png';
  }

  cancelLinkInsertion() {
    this.cancelRequest.next();
    this.isGettingFavicon = false;
    this.linkValue = '';
    this.linkTextValue = '';
    this.linkTypeValue = undefined;
    this.urlTypeSelected = false;
    this.isAddingLink = false;
  }

  insertLink() {
    this.isAddingLink = true;
    this.isEditingLink = false;
    this.guildLinks.forEach((link) => {
      link.editing = false;
    });
    this.linkValue = '';
    this.linkTextValue = '';
    this.linkTypeValue = undefined;
    this.urlTypeSelected = false;

    setTimeout(() => {
      this.linkInputField.nativeElement.focus();
    });
  }

  addLink() {
    this.processLink(true);
  }

  updateLink(link: IGuildExternalLink) {
    const lastSelectedLinkValue = this.lastSelectedLinkValues.get(link.id);

    if (this.linkValue === lastSelectedLinkValue?.url && this.linkTextValue === lastSelectedLinkValue.linkText) {
      this.cancelEdit(link);
      return;
    }

    if (this.linkValue === lastSelectedLinkValue?.url && this.linkTextValue !== lastSelectedLinkValue.linkText) {
      link.linkText = this.linkTextValue.length ? this.linkTextValue : lastSelectedLinkValue.linkText;
      this.editLinkEvent.emit(link);
      this.cancelEdit(link);
      return;
    }

    if (this.linkValue !== lastSelectedLinkValue?.url) {
      this.processLink(false);
    }
  }

  deleteLink(link: IGuildExternalLink) {
    this.deleteLinkEvent.emit(link);
  }

  editLink(link: IGuildExternalLink) {
    if (this.isAddingLink === true) {
      this.cancelLinkInsertion();
    }

    this.isEditingLink = true;
    link.editing = true;

    this.linkToBeEdited = link;
    this.lastSelectedLinkValues.set(link.id, { url: link.url, linkText: link.linkText } as SavedLinkProperties);

    const index = this.links.indexOf(link);
    this.linkValue = this.links[index].url;
    this.linkTextValue = this.links[index].linkText;
    this.linkTypeValue = this.links[index].linkType;
    this.urlTypeSelected = false;
  }

  cancelEdit(link: IGuildExternalLink) {
    this.cancelRequest.next();
    this.lastSelectedLinkValues.delete(link.id);
    this.isGettingFavicon = false;
    this.linkValue = '';
    this.linkTextValue = '';
    this.linkTypeValue = undefined;
    this.isEditingLink = false;
    this.urlTypeSelected = false;

    link.editing = false;
  }

  expandLinks(): void {
    this.linksState = { id: this.guildId, amount: this.guildLinks.length, expanded: true };
    this.removeLinkState();
  }

  collapseLinks(): void {
    this.linksState = { id: this.guildId, amount: 3, expanded: false };
    this.saveLinkState();
  }

  getLinkState(): void {
    const linksState = this.localStorageService.get<LinksState>('expandedLinksStateID: ' + this.guildId);
    if (linksState && linksState.expanded === false) {
      this.linksState = linksState;
    } else {
      this.linksState = { id: this.guildId, amount: this.guildLinks.length, expanded: true };
    }
  }

  saveLinkState(): void {
    this.localStorageService.set('expandedLinksStateID: ' + this.guildId, this.linksState);
  }

  removeLinkState(): void {
    this.localStorageService.remove('expandedLinksStateID: ' + this.guildId);
  }

  validateUrl() {
    const processedUrl = this.processUrl(this.linkValue.trim());
    const validUrl = validateUrl.isWebUri(processedUrl);
    return validUrl;
  }

  validateLink(): boolean {
    if (this.linkTypeValue === undefined || this.linkTypeValue === null || !this.linkValue) {
      this.hasAttemptedToAddIncompleteLink = true;
      setTimeout(() => {
        this.hasAttemptedToAddIncompleteLink = false;
      }, 3000);
      return false;
    }

    if (this.linkExists()) {
      if (this.isAddingLink && !this.isEditingLink) {
        this.hasAttemptedToAddExistingLink = true;
        setTimeout(() => {
          this.hasAttemptedToAddExistingLink = false;
        }, 3000);
      } else if (!this.isAddingLink && this.isEditingLink) {
        this.message.error("You already have this link");
      }

      return false;
    }

    if (!this.validateUrl()) {
      this.hasAttemptedToAddInvalidLink = true;
      setTimeout(() => {
        this.hasAttemptedToAddInvalidLink = false;
      }, 3000);
      return false;
    }
    return true;
  }

  linkExists() {
    return this.links.some((link) => link.url === this.processUrl(this.linkValue.trim()));
  }

  selectUrlPrefix(): void {
    const link = this.linkProperties.get(this.linkTypeValue!);

    if (link === this.linkProperties.get(ELinkType.Website) && this.linkValue) {
      this.selectLinkTypeBasedOnUrl(this.linkValue);
      if (this.linkTypeValue === ELinkType.Other) {
        this.linkTypeValue = ELinkType.Website;
      }
      return;
    }

    this.linkValue = link!.url;
    this.linkTypeImage = link?.thumbnail;

    if (this.linkTypeValue === ELinkType.Other) {
      this.urlTypeSelected = false;
    } else {
      this.urlTypeSelected = true;
    }

    setTimeout(() => {
      //not exactly sure what this does, but I read somewhere that the UI needs a little time after rendering to select the element automatically. Without the timeout, it doesn't seem to work.
      this.linkInputField.nativeElement.select();
    });
  }

  selectLinkTypeBasedOnUrl(newLinkValue: string): void {
    for (const link of this.linkProperties.values()) {
      if (newLinkValue.toLowerCase().trim().match(link.name.toLowerCase())) {
        this.linkTypeValue = link.type;
        return;
      }
    }

    this.linkTypeValue = ELinkType.Other;
  }

  getLinkThumbnail(): string | undefined {
    const link = this.linkProperties.get(this.linkTypeValue!);
    return link?.thumbnail;
  }

  getLinkIcon(): string | undefined {
    const link = this.linkProperties.get(this.linkTypeValue!);
    return link?.icon;
  }

  private processUrl(url: string): string {
    // Trim the URL and check if it starts with http:// or https://
    const trimmedUrl = url.trim();
    const hasHttp = /^http:\/\//i.test(trimmedUrl);

    // Remove http://, https://, and optionally www.
    let processedLinkValue = trimmedUrl.replace(/^https?:\/\//i, '').replace(/^www\./, '');

    // Check if the URL ends with .com (possibly followed by a path or parameters)
    const endsWithDotCom = /\.com(\/|$)/.test(processedLinkValue);
    const hasSecondLevelDomain = /(\.[a-z]{2,}\.([a-z]{2,})(\/|$))|(\.com(\/|\?|#|$))/.test(processedLinkValue);

    // If it doesn't end with .com or it's part of a longer domain (excluding paths), and does not have a second-level domain
    if (!endsWithDotCom && !hasSecondLevelDomain) {
      processedLinkValue += '.com';
    }

    // Normalise to remove any trailing '.com/' to '.com'
    processedLinkValue = processedLinkValue.replace(/\.com\/$/, '.com');

    // Prepend http:// if originally specified, otherwise prepend https://
    processedLinkValue = hasHttp ? `http://${processedLinkValue}` : `https://${processedLinkValue}`;

    return processedLinkValue;
  }

  private processLink(isAddOperation: boolean) {
    if (this.validateLink()) {
      this.isGettingFavicon = true;
      const trimmedLink = this.linkValue.trim();
      const processedUrlValue = this.processUrl(trimmedLink);
      const baseUrl = new URL(processedUrlValue);
      this.guildService.getGuildLinkFavicon(`${baseUrl.origin}`)
        .pipe(
          takeUntil(this.cancelRequest)
        )
        .subscribe({
          next: (res: GuildLinkData) => {
            let link;
            if (isAddOperation) {
              link = {
                linkType: this.linkTypeValue,
                url: `${processedUrlValue}`,
                linkText: res.pageTitle,
                otherLinkTypeName: this.linkType[this.linkTypeValue!],
                otherLinkTypeThumbnail: this.linkTypeValue === this.linkType.Other && res.faviconUrl ? res.faviconUrl : this.getLinkThumbnail(),
                icon: this.getLinkIcon(),
                editing: false
              } as IGuildExternalLink;
              this.addLinkEvent.emit(link);
              setTimeout(() => { this.isAddingLink = false; });
            } else {
              const index = this.links.findIndex((l) => l.id === this.linkToBeEdited.id);
              this.links[index] = {
                id: this.links[index].id,
                linkType: this.linkTypeValue,
                url: `${processedUrlValue}`,
                linkText: this.linkTextValue.length ? this.linkTextValue : processedUrlValue,
                otherLinkTypeName: this.linkType[this.linkTypeValue!],
                otherLinkTypeThumbnail: this.linkTypeValue === this.linkType.Other && res.faviconUrl ? res.faviconUrl : this.getLinkThumbnail(),
                icon: this.getLinkIcon(),
                editing: false,
              } as IGuildExternalLink;
              if (JSON.stringify(this.linkToBeEdited.url) !== JSON.stringify(this.links[index].url)) {
                this.editLinkEvent.emit(this.links[index]);
              }
              setTimeout(() => { this.isEditingLink = false; });
            }
            this.hasAttemptedToAddExistingLink = false;
            this.hasAttemptedToAddIncompleteLink = false;
            this.hasAttemptedToAddInvalidLink = false;
            this.linkValue = '';
            this.linkTextValue = '';
            this.linkTypeValue = undefined;
            this.urlTypeSelected = false;
            this.isGettingFavicon = false;
          },
          error: (err) => {
            console.error("Failed to get Favicon", err);
            this.message.info(`Unfortunately, the icon for this link isn't available.`)
            this.isGettingFavicon = false;
          }
        })
    }
  }
}
