/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

// external services
import { LocalStorageService } from 'ngx-localstorage';
import { NzMessageService } from 'ng-zorro-antd/message';

// models
import { ActivityKind } from 'src/app/types/activity-kind.type';
import { IActivitiesTableData } from 'src/app/models/activities-table-date.model';
import { ILearningType } from 'src/app/models/learning-type.model';
import { IUserAction, getUserActionStatus } from 'src/app/models/user-action.model';
import { IUserActivity, getUserActivityStatus } from 'src/app/models/user-activity.model';
import { IUserPlaylist, IUserPlaylistState, getUserPlaylistStatus } from 'src/app/models/user-playlist.model';
import { SearchActivitiesPipe } from '../search-activities.pipe';

// types
import { StatusKind } from 'src/app/types/status-kind.type';
import { ICheckboxItems } from 'src/app/models/checkbox-items.model';

// services
import { GoalService } from 'src/app/services/goal.service';
import { UserActivitiesService } from 'src/app/services/user-activities.service';
import { LearningService } from 'src/app/services/learning.service';
import { UserActionService } from 'src/app/services/user-action.service';
import { UserPlaylistService } from 'src/app/services/user-playlist.service';
import { PlaylistService } from 'src/app/services/playlist.service';
import { ISkill } from 'src/app/models/skill.model';
import { EIconSize } from 'src/app/generic-components/types/icon-size';

// pipes
export interface PlaylistCollapsedState {
  id: number;
}

@Component({
  selector: 'sl-profile-activities-table',
  templateUrl: './profile-activities-table.component.html',
  styleUrls: ['./profile-activities-table.component.less'],
})
export class ProfileActivitiesTableComponent implements OnInit, OnDestroy {
  public filteredRowItems: IActivitiesTableData[] = [] as IActivitiesTableData[];
  public showOptions!: boolean;
  public query!: string;
  public selectedTitleString = 'All Selected';
  public hasActiveGoals = false;
  public statusFilterItems!: ICheckboxItems[];
  ActivityKind = ActivityKind; // required to map so we can use it in the template.
  getUserActivityStatus = getUserActivityStatus; // required to map so we can use it in the template.
  getUserActionStatus = getUserActionStatus; // required to map so we can use it in the template.

  // infinite scroll
  readonly MAX_ACTIVITIES = 24; // the ammount of list items needed to fill a larger screen
  lastActivityIndex = this.MAX_ACTIVITIES;
  throttle = 0;
  scrollDistance = 5;

  allUserActivities!: IUserActivity[];
  allUserActions!: IUserAction[];
  learningTypes!: ILearningType[];
  readonly defaultCardImageUrl = '/assets/img/sealadder/sealadder-logo-96x96.png';
  editId: number | null = null;
  dateFormat = 'dd MMM yyyy';
  coreSkillIconSize = EIconSize;

  playlistCollapsedState: PlaylistCollapsedState[] = [];

  // for ngZorro modal
  isAPlaylist = false;
  isVisible = false;
  isDeleting = false;

  private isLoadingGoals = false;
  private isLoadingActivities = false;
  private userPlaylistsInitial: IUserPlaylist[] = [];
  private searchActivities = new SearchActivitiesPipe();
  private onChangeGoal!: Subscription;
  // tslint:disable: variable-name
  constructor(
    private goalService: GoalService,
    private userActivitiesService: UserActivitiesService,
    private userPlaylistService: UserPlaylistService,
    private playlistService: PlaylistService,
    private userActionService: UserActionService,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private learningService: LearningService,
    private message: NzMessageService,
  ) { }

  ngOnInit() {
    this.showOptions = true;
    this.initializeStatusFilterItems();
    this.getAllOrNoneSelected();
    const topSkillName = this.route.snapshot.paramMap.get('skillName');
    this.query = topSkillName ? topSkillName : '';
    this.hasGoalsInit();
    this.initTableData();
    this.getLearningTypes();
  }

  ngOnDestroy() {
    if (this.onChangeGoal) {
      this.onChangeGoal.unsubscribe();
    }
  }

  getLearningTypes() {
    this.learningService.getLearningTypes().subscribe((ret: ILearningType[]) => (this.learningTypes = ret));
  }

  public isLoading(): boolean {
    return this.isLoadingActivities || this.isLoadingGoals;
  }

  hasGoalsInit() {
    // console.log('*** Start loading goals');
    this.onChangeGoal = this.goalService.getGoalsUpdatedEvent().subscribe(
      () => {
        this.updateHasGoals();
      },
      (error) => {
        this.message.error(`Error loading activities goals. Error Message: ${error.message}`);
      },
      () => {
        this.isLoadingGoals = false;
      }
    );

    this.updateHasGoals();
  }

  public getTitle(activity: IUserActivity): string {
    if (activity.title) {
      return activity.title;
    }

    return activity.link;
  }

  public getNumberOfActiveActivities(activities: IUserActivity[]): number {
    const activityCount = activities.length;
    const completedActivities = this.getNumberOfCompletedActivities(activities);
    return activityCount - completedActivities;
  }

  public getNumberOfCompletedActivities(activities: IUserActivity[]): number {
    let completedActivities = 0;

    activities.forEach((activity) => {
      if (activity.status === 'completed') {
        completedActivities++;
      }
    });
    return completedActivities;
  }

  public getPercentageOfCompletedActivities(activities: IUserActivity[]): number {
    return Math.round((this.getNumberOfCompletedActivities(activities) / activities.length) * 100);
  }

  public getTitleForPlaylist(playlist: IUserPlaylist): string {
    return playlist.playlistTitle + ' (v' + playlist.playlistVersion + ')';
  }

  public getTitleForAction(action: IUserAction): string {
    if (action.title) {
      return action.title;
    }

    return 'No title found';
  }

  public togglePlaylistProgressIndicator(activityRow: IActivitiesTableData, event: Event) {
    event.stopPropagation();
    activityRow.userPlaylist.completedActivitiesBadgeView = !activityRow.userPlaylist.completedActivitiesBadgeView;
  }

  updateUserPlaylistActivities(userPlaylist: IUserPlaylistState) {
    this.userActivitiesService.updateActivitiesCache();
    this.initTableData();

    if (userPlaylist.playlistVersion === userPlaylist.latestPlaylistVersion) {
      this.message.success(`The userplaylist with id ${userPlaylist.id} has been updated to the latest version`);
    } else {
      this.message.success(`The userplaylist with id ${userPlaylist.id} has been updated to version ${userPlaylist.playlistVersion}`);
    }
  }

  updateHasGoals() {
    if (this.goalService.isInitialised()) {
      this.hasActiveGoals = this.goalService.getGoals().filter((e) => e.dateCompleted == null).length > 0;
    }
  }

  initializeStatusFilterItems() {
    const activityFilterItems = this.localStorageService.get<ICheckboxItems[]>('activityFilterItems') as ICheckboxItems[];

    if (activityFilterItems) {
      const testType = activityFilterItems.length ? typeof activityFilterItems[0].value : '';

      if (testType === 'number') {
        this.statusFilterItems = activityFilterItems;
        return;
      }
    }

    this.statusFilterItems = [
      { name: 'Scheduled', checked: true, value: StatusKind.Scheduled },
      { name: 'In Progress', checked: true, value: StatusKind.InProgres },
      { name: 'Completed', checked: true, value: StatusKind.Completed },
      { name: 'Archived', checked: false, value: StatusKind.Archived },
    ];
  }

  onChecked() {
    this.getAllOrNoneSelected();
    this.sortActivitiesList();
    this.applyFilters();

    this.localStorageService.set('activityFilterItems', this.statusFilterItems);
  }

  initTableData() {
    this.userActivitiesService.getActivitiesUpdatedEvent().subscribe({
      next: () => {
        this.getUserActivities();
        this.applyFilters();
      },
      error: (error) => console.error(`*** ProfileActivitiesTableComponent: Failed to get activities updating event. Error: ${error}`),
    });
    this.getUserActivities();
    this.getUserPlaylists();
    this.getUserActions();
    this.applyFilters();
  }

  applyFilters() {
    const filterItems: ICheckboxItems[] = this.filterStatusItems();
    this.filteredRowItems.length = 0;

    const searchedActivities = this.searchActivities.transform(this.allUserActivities, this.query);
    if (!searchedActivities || searchedActivities.length === 0) {
      this.filteredRowItems = [];
      return;
    }

    const statusFilteredActivities = searchedActivities.filter((aa) => {
      let included = false;
      filterItems.forEach((checkboxItem) => {
        if (checkboxItem.value === getUserActivityStatus(aa)) {
          included = true;
          return;
        }
      });
      return included;
    });

    this.filteredRowItems = this.groupPlaylistActivities(statusFilteredActivities);
    this.updateCollapsedPlaylistStateFromLocalStorage();
    if (this.allUserActions) {
      this.allUserActions.forEach((ua) => {
        this.filteredRowItems.push({ userAction: ua, activityRowType: ActivityKind.UserAction, status: this.getUserActionStatus(ua) } as IActivitiesTableData);
      });
      this.sortActivitiesList();
    }
  }

  sortActivitiesList() {
    this.filteredRowItems.sort((a, b) => a.status - b.status);
  }

  filterStatusItems(): ICheckboxItems[] {
    return this.statusFilterItems.filter((item) => item.checked);
  }

  getAllOrNoneSelected() {
    const filterCount: ICheckboxItems[] = this.filterStatusItems();
    if (filterCount.length === this.statusFilterItems.length) {
      this.selectedTitleString = 'All Selected';
    } else if (filterCount.length === 0) {
      this.selectedTitleString = 'None';
    } else {
      this.selectedTitleString = this.buildStatusFilterItemsString(filterCount);
    }
  }

  setPlannedDate(activity: IUserActivity, plannedStartDate: Date) {
    plannedStartDate.setHours(12);
    plannedStartDate.setMinutes(0);
    plannedStartDate.setSeconds(0);
    plannedStartDate.setMilliseconds(0);
    activity.datePlannedStart = plannedStartDate;

    this.userActivitiesService.updateUserActivity(activity).subscribe(
      (resultActivity) => {
        activity = resultActivity;
        // console.log(`Successfully updated the set planned date to: ${plannedStartDate.toDateString}`);
      },
      (error) => {
        this.message.error('There was an error setting the Planned Start Date.');
        console.error(error);
      }
    );
  }

  plannedDateIsBehind(activity: IUserActivity): boolean {
    // behind if the activity is not started (dateStart is still null) and it's in the past
    if (!activity.dateStart) {
      const before = !activity.datePlannedStart || new Date(activity.datePlannedStart) < new Date() ? true : false;
      return before;
    }

    return false;
  }

  disabledDates = (currentDate: Date): boolean => (currentDate.toDateString() === new Date().toDateString() ? false : currentDate < new Date());

  buildStatusFilterItemsString(input: ICheckboxItems[]): string {
    let returnString = '';
    if (input.length > 0) {
      input.forEach((item) => {
        returnString += item.name + ', ';
      });
      returnString = returnString.substring(0, returnString.length - 2);
    }
    return returnString;
  }

  changeActivityModalState() {
    this.isVisible = false;
  }

  changeActivityType(status: boolean) {
    this.isAPlaylist = status;
  }

  getProviderTooltip(providerName: string): string {
    const tooltip = providerName === 'unverified' ? 'Unverified activities that we don\'t have a connection to the provider in order to confirm that you\'ve done it.' : providerName;

    return tooltip;
  }

  // ----- For NgZorroModal
  showModal = () => {
    if (!this.hasActiveGoals) {
      this.message.error(`You don't have any active goals.`);
    } else {
      this.isVisible = true;
    }
  };

  handleCancel = () => {
    this.isVisible = false;
  };

  getGoalSignature(goalId: number): string {
    const goal = this.goalService.getGoal(goalId);
    return goal ? goal.specialisation + ', ' + goal.level : '-';
  }

  getActivityTypeIcon(typeName: string): string | undefined {
    let result = null;
    if (this.learningTypes) {
      result = this.learningTypes.find((lt) => lt.type === typeName);
    }
    return result ? result.icon : undefined;
  }

  getActivityImageUrl(url: string): string {
    if (url) {
      return url;
    }
    return this.defaultCardImageUrl;
  }

  startEdit(id: number): void {
    this.editId = id;
  }

  onChangeActivity(date: Date, activity: IUserActivity) {
    this.userActivitiesService.updateUserActivity(activity).subscribe((resultActivity) => {
      activity = resultActivity;
    });
    this.editId = null;
  }

  onChangeAction(date: Date, action: IUserAction) {
    this.userActionService.updateUserAction(action).subscribe((resultAction) => {
      action = resultAction;
    });
    this.editId = null;
  }

  onScrollDown() {
    this.isLoadingActivities = true;
    setTimeout(() => {
      this.lastActivityIndex += 10;
    }, 10);
  }

  expandPlaylist(activityRow: IActivitiesTableData): void {
    activityRow.userPlaylist.expanded = !activityRow.userPlaylist.expanded;
    if (!this.containsPlaylistId(activityRow.userPlaylist.id)) {
      this.collapsePlaylist(activityRow.userPlaylist.id);
    } else {
      const index = this.playlistCollapsedState.findIndex((item) => item.id === activityRow.userPlaylist.id);
      this.playlistCollapsedState.splice(index, 1);
    }
    this.localStorageService.set('playlistCollapsedState', this.playlistCollapsedState);
  }

  collapsePlaylist(playlistId: number) {
    this.playlistCollapsedState.push({ id: playlistId });
  }

  containsPlaylistId(id: number): boolean {
    return this.playlistCollapsedState.some((item) => JSON.stringify(id) === JSON.stringify(item.id));
  }

  getScrolledActivityList(): IActivitiesTableData[] {
    if (this.lastActivityIndex >= this.filteredRowItems.length) {
      this.isLoadingActivities = false;
      return this.filteredRowItems;
    }
    return this.filteredRowItems.slice(0, this.lastActivityIndex);
  }

  deleteUserPlaylist(userPlaylistId: number) {
    this.isDeleting = true;
    this.removePlaylistFromUserPlaylistsInitial(userPlaylistId);
    this.applyFilters();
  }

  updateCollapsedPlaylistStateFromLocalStorage() {
    const lsPlaylistCollapsedState = this.localStorageService.get<PlaylistCollapsedState[]>('playlistCollapsedState');
    if (!lsPlaylistCollapsedState) {
      this.playlistCollapsedState = [];
    } else {
      this.playlistCollapsedState = lsPlaylistCollapsedState;
    }
  }

  doesUserActivityContainACoreSkill(userActivity: IUserActivity): boolean {
    return userActivity.skills.some(skill => skill && skill.coreSkill === true);
  }

  // -------------------------------------------------------------------------------------------------------------------------
  // GroupPlaylistActivities will take the data from the initial activities and the initial activities and create the combined
  // data structures for use in the table. Given the following 2 inputs:
  // activities       Playlists
  // ---------------------------------
  // 1 act1 y           P1
  // 2 act2 n             1
  // 3 act3 y           P2
  // 4 act4 n             3
  // This function will produce a combined list like this:
  // P1
  //   Act1
  // P2
  //   Act3
  // Act2
  // Act4
  // -------------------------------------------------------------------------------------------------------------------------
  private groupPlaylistActivities(filteredActivities: IUserActivity[]): IActivitiesTableData[] {
    const allAvailable: IActivitiesTableData[] = [];
    // go through each of the incomming activities and, if it's in the playlists, add the playlist (if it's not already),
    // if its not, add it to the new list as a userActivity.
    filteredActivities.forEach((userActivity) => {
      let foundInAtLeastOnePlaylist = false;
      this.userPlaylistsInitial.forEach((playlist) => {
        const foundActivityId = playlist.userActivityIds.find((plActivityId) => plActivityId === userActivity.id);
        if (foundActivityId) {
          let playlistInAllAvailable = allAvailable.find((a) => a.userPlaylist?.id === playlist.id);
          if (!playlistInAllAvailable) {
            playlistInAllAvailable = { userPlaylist: playlist, activityRowType: ActivityKind.UserPlaylist, status: getUserPlaylistStatus(playlist) } as IActivitiesTableData;
            this.updateCollapsedStateOfPlaylist(playlistInAllAvailable);
            allAvailable.push(playlistInAllAvailable);
          }

          if (!playlistInAllAvailable.playlistActivities) {
            playlistInAllAvailable.playlistActivities = [];
          }

          playlistInAllAvailable.playlistActivities.push(userActivity);
          foundInAtLeastOnePlaylist = true;
        }
      });

      // so, it's an activity that's NOT in a playlist
      if (!foundInAtLeastOnePlaylist) {
        if (allAvailable.findIndex((aa) => aa.userActivity?.id === userActivity.id) === -1) {
          allAvailable.push({ userActivity, activityRowType: ActivityKind.UserActivity, status: getUserActivityStatus(userActivity) } as IActivitiesTableData);
        } else {
          console.warn(`We have found a duplicate user activity for a user's activity list. UserActivity.ID: ${userActivity.id}`);
        }
      }
    });

    return allAvailable;
  }

  private updateCollapsedStateOfPlaylist(tableData: IActivitiesTableData) {
    if (this.playlistCollapsedState.length === 0 || !this.playlistCollapsedState) {
      tableData.userPlaylist.expanded = true;
    } else {
      if (this.containsPlaylistId(tableData.userPlaylist.id)) {
        tableData.userPlaylist.expanded = false;
      } else {
        tableData.userPlaylist.expanded = true;
      }
    }
  }

  private async getUserActivities() {
    try {
      this.allUserActivities = await this.userActivitiesService.getUserActivities();
      this.getCoreSkillsForUserActivities(this.allUserActivities);
    } catch (error) {
      console.error('Failed to load user activities', error);
      this.message.error(`We couldn't load the user activities. Please try again later.`)
    }
  }

  private getCoreSkillsForUserActivities(userActivities: IUserActivity[]) {
    userActivities.forEach(userActivity => {
      if (userActivity.goalId) {
        const goal = this.goalService.getGoal(userActivity.goalId);
        if (goal && goal.skills && Array.isArray(userActivity.skills)) {
          userActivity.skills.forEach(skill => {
            if (!skill) return;
            const skillMatch = goal.skills.find(goalSkill => goalSkill.id === skill.id);
            if (skillMatch && this.isUserActivitySkillATopSkill(skillMatch)) {
              skill.coreSkill = true;
            } else {
              skill.coreSkill = false;
            }
          })
        }
      }
    })
  }

  private isUserActivitySkillATopSkill(skill: ISkill): boolean {
    if (skill.coreSkill) {
      return true;
    }
    return false;
  }

  private getAvailablePlaylistVersions(userPlaylist: IUserPlaylist) {
    this.playlistService.getAvailableUserPlaylistVersions(userPlaylist.playlistId).subscribe(
      (res) => {
        userPlaylist.latestPlaylistVersion = res[0];
        userPlaylist.availablePlaylistVersions = res;
      },
    );
  }

  private getUserPlaylists() {
    this.userPlaylistService.getUserPlaylists().subscribe((res: IUserPlaylist[]) => {
      const playlistsWithActivities = res.filter((pl) => pl.userActivityIds.length > 0);
      if (playlistsWithActivities.length !== res.length) {
        const emptyPlaylists = res.filter((pl) => pl.userActivityIds.length === 0);
        emptyPlaylists.forEach((pl) => {
          this.message.warning(`We received a user playlist without activities. UserPlaylistId: ${pl.id}`);
        });
        console.table(emptyPlaylists);
      }
      res.forEach((pl) => {
        this.getAvailablePlaylistVersions(pl);
      });
      this.userPlaylistsInitial = playlistsWithActivities;
      this.applyFilters();
    });
  }

  private getUserActions() {
    this.userActionService.getUserActions().subscribe(
      (res: IUserAction[]) => {
        this.allUserActions = res;
        this.applyFilters();
      },
      (error) => {
        this.message.error(`Error loading Actions. Error Message: ${error.message}`);
      }
    );
  }

  private removePlaylistFromUserPlaylistsInitial(userPlaylistId: number) {
    const index = this.userPlaylistsInitial.findIndex((upi) => upi.id === userPlaylistId);
    if (index > -1) {
      this.userPlaylistsInitial.splice(index, 1);
    }
  }

  private removeActionFromActionList(userActionId: number) {
    const index = this.allUserActions.findIndex((ua) => ua.id === userActionId);
    if (index > -1) {
      this.allUserActions.splice(index, 1);
    }
  }
}
