/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable } from 'rxjs';

// ngZorro
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';

// models
import { IProvider } from 'src/app/models/provider.model';
import { IUserProfileSubscription } from 'src/app/models/user-profile.model';
import { IUserProvider, IProviderLoginName } from 'src/app/models/user-provider.model';

// types
import { EProfessionType } from '../../types/profession.type';

// services
import { AccountDetailsService } from 'src/app/services/account-details.service';
import { CareerDetailsService } from 'src/app/services/career-details.service';
import { EmailMessageService } from 'src/app/services/email-message.service';
import { GoalService } from 'src/app/services/goal.service';
import { GuildService } from 'src/app/services/guild.service';
import { ISubscription } from 'src/app/models/subscription.model';
import { LearningService } from 'src/app/services/learning.service';
import { PlaylistService } from 'src/app/services/playlist.service';
import { ProvidersService } from 'src/app/services/providers.service';
import { RecommendationService } from 'src/app/services/recommendation.service';
import { SkillsReportService } from 'src/app/services/skillsreport.service';
import { toTitleCase } from 'src/app/services/helper.service';
import { TrackService } from 'src/app/services/track.service';
import { UserActivitiesService } from 'src/app/services/user-activities.service';
import { SkillService } from 'src/app/services/skill.service';

@Component({
  selector: 'sl-seed-database',
  templateUrl: './seed-database.component.html',
  styleUrls: ['./seed-database.component.less'],
})
export class SeedDatabaseComponent implements OnInit {
  @ViewChildren('deleteInput') deleteInputs!: QueryList<ElementRef>;
  fileList: NzUploadFile[] = [];
  professionType = EProfessionType;
  isLoadingCategories = false;
  isLoadingLevelDetails: { [type: string]: boolean } = {};
  isLoadingGoals: { [type: string]: boolean } = {};
  isLoadingImages = false;
  isLoadingActions = false;
  isLoadingProfiles = false;
  isLoadingProviders = false;
  isLoadingLearning = false;
  isLoadingRecommendations = false;
  isLoadingTracks = false;
  isLoadingEmailMessages = false;
  isLoadingGuilds = false;
  isLoadingGenerateTrackDescriptions = false;
  isLoadingRoles = new Map<string, boolean>();
  isLoadingTrial = new Map<string, boolean>();
  isLoadingDeleted = new Map<string, boolean>();
  isLoadingIncludeDeleted = false;
  includeDeletedUsers = false;
  showDeletedInput = new Map<string, boolean>();
  deleteReasons = new Map<string, string>();
  isAddingMetadata = false;
  isSubscribed = false;
  isTrial = false;
  isUpdatingSkillsCache = false;
  isUpdatingInvitations = false;
  isUpdatingUsernames = false;
  isUpdatingProvidersTransfer = false;
  isDetectingTrackProblems = false;
  isMarkingCurrentGuildOwnersAsMembers = false;
  isFixDuplicateTracks = false;
  isCleaningEmptySkills = false;
  isCleaningEmptyPlaylistActivities = false;
  isCleaningUntrimmedSkills = false;
  providers: IProvider[] = new Array<IProvider>();
  userProviders: IUserProvider[] = new Array<IUserProvider>();
  userSubscription?: ISubscription;
  notTestUserIndex = new Map<string, number>();

  constructor(
    private message: NzMessageService,
    private accountDetailsService: AccountDetailsService,
    private goalService: GoalService,
    private learningService: LearningService,
    private providersService: ProvidersService,
    private recommendationService: RecommendationService,
    private userActivitiesService: UserActivitiesService,
    private skillsReportService: SkillsReportService,
    private trackService: TrackService,
    private emailMessageService: EmailMessageService,
    private guildService: GuildService,
    private playlistService: PlaylistService,
    private careerDetailsService: CareerDetailsService,
    private skillsService: SkillService,
  ) { }

  ngOnInit() {
    this.isLoadingIncludeDeleted = false;
    this.accountDetailsService.getBasicUsers(this.includeDeletedUsers).subscribe({
      next: (users) => {
        const userMap = new Map(users.map(user => [user.id, user]));

        // Filter out userProviders that are not in the returned users list
        this.userProviders = this.userProviders.filter(provider => userMap.has(provider.user.id));

        // Update existing providers and add new ones
        users.forEach(user => {
          const existingProvider = this.userProviders.find(provider => provider.user.id === user.id);
          const userProvider = {
            user,
            publicUrl: user.publicUrl,
            isAdminProtected: user.email.includes('admin@admin.com') || user.id === '0',
            isTestProtected: user.id === '0',
            providerSet: existingProvider ? existingProvider.providerSet : [],
            numGoals: user.numGoals,
            numActivities: user.numActivities,
            numActivitiesCompleted: user.numActivitiesCompleted,
          };

          if (existingProvider) {
            Object.assign(existingProvider, userProvider);
          } else {
            this.userProviders.push(userProvider);
          }
        });

        this.userProviders.sort(this.compareIUserProvider);
        this.initTestUserIndex();
        this.updateProviders();
      },
      error: (error) => {
        this.message.error(`Failed to identify the users in the system. Check the network connection. Error: ${error}`, { nzDuration: 10000, nzPauseOnHover: true });
      },
      complete: () => {
        this.isLoadingIncludeDeleted = false;
      }
    });
  }

  public seedDatabaseProviders() {
    this.isLoadingProviders = true;
    this.providersService.seedDatabase().subscribe({
      next: (_ret) => {
        this.message.success('Bazinga! Providers are locked in.');
      },
      error: (error) => {
        this.isLoadingProviders = false;
        this.message.error(`Failed to successfully seedDatabase for providers. Error: ${error.message}`, { nzDuration: 10000, nzPauseOnHover: true });
      },
      complete: () => {
        this.isLoadingProviders = false;
      }
    }
    );
  }

  public seedDatabaseLearning() {
    this.isLoadingLearning = true;
    this.learningService.seedDatabase().subscribe({
      next: (_ret) => {
        this.message.success('Boom! Learning types have been seeded.');
      },
      error: (error) => {
        this.message.error(`Failed to successfully seedDatabase for learning. Error: ${error.message}`, { nzDuration: 10000, nzPauseOnHover: true });
      },
      complete: () => {
        this.isLoadingLearning = false;
      }
    });
  }

  public seedDatabaseProfiles() {
    this.isLoadingProfiles = true;
    this.accountDetailsService.seedDatabase().subscribe({
      next: (_ret) => {
        this.message.success('Click-Click! You\'re in.');
      },
      error: (error) => {
        this.message.error(`Failed to successfully seedDatabase for profiles. Error: ${error.message}`, { nzDuration: 10000, nzPauseOnHover: true });
      },
      complete: () => {
        this.isLoadingProfiles = false;
      }
    });
  }

  public seedLevelDetails(industry: string) {
    this.isLoadingLevelDetails[industry] = true;
    this.careerDetailsService.SeedLevelDetailsExperienceLevels(industry).subscribe({
      next: (_ret) => {
        this.message.success(`Boom-Shack-a-lack-a! The Level Details for '${toTitleCase(industry)}' are now seeded.`);
      },
      error: () => {
        this.message.error(`Seeding Level Details has failed for for '${toTitleCase(industry)}'. Likely a problem in the backend. Time to debug`, { nzDuration: 10000, nzPauseOnHover: true });
      },
      complete: () => {
        this.isLoadingLevelDetails[industry] = false;
      }
    });
  }

  public seedSkills(industry: string) {
    this.isLoadingGoals[industry] = true;
    this.message.info('Please wait, this can sometimes take a few minutes');
    this.goalService.seedDatabase(industry).subscribe({
      next: (_ret) => {
        this.message.success(`Boom-Shack-a-lack-a! The skills for '${toTitleCase(industry)}' are now allocated to dummy goals.`, { nzDuration: 5000 });
        this.message.info('Please also seed recommendations as previously seeded recommendations would be removed', { nzDuration: 5000 });
      },
      error: () => {
        this.message.error(`Seeding Goals has failed for for '${toTitleCase(industry)}'. Likely a problem in the backend. Time to debug`, { nzDuration: 10000, nzPauseOnHover: true });
      },
      complete: () => {
        this.isLoadingGoals[industry] = false;
      }
    });
  }

  public seedDatabaseCategoriesAndIcons() {
    this.isLoadingCategories = true;
    this.goalService.seedCategoriesAndIcons().subscribe({
      next: (_ret) => {
        this.message.create('success', 'Ding! That\'s the sound of pretty pictures and icons for categories weaving into the product.');
      },
      error: (error) => {
        this.message.error(`Failed to set categories and icons. Error: ${error.message}`, { nzDuration: 10000, nzPauseOnHover: true });
      },
      complete: () => {
        this.isLoadingCategories = false;
      }
    });
  }

  public seedActions() {
    this.isLoadingActions = true;
    this.recommendationService.seedSuggestedActions().subscribe({
      next: (ret) => {
        this.message.success(`Boom mutherfarker! ${ret.length} actions are locked into the database.`);
      },
      error: (error) => {
        this.message.error(`Failed to seed suggested Actions. Error: ${error.message}`, { nzDuration: 10000, nzPauseOnHover: true });
      },
      complete: () => {
        this.isLoadingActions = false;
      }
    });
  }

  public seedEmailMessages() {
    this.isLoadingEmailMessages = true;
    this.emailMessageService.seedEmailMessages().subscribe({
      next: (ret) => {
        this.message.success(`Pow! ${ret.length} Email templates are locked in place and emails are ready to be fired.`);
      },
      error: (error) => {
        this.message.error(`Oh no, something went wrong. Email messages were NOT initialised`);
        console.error(`Failed to successfully seedEmailMessages. Error: ${error.message}`, { nzDuration: 10000, nzPauseOnHover: true });
      },
      complete: () => {
        this.isLoadingEmailMessages = false;
      }
    });
  }

  public seedGuilds() {
    this.isLoadingGuilds = true;
    this.guildService.seedGuilds().subscribe({
      next: (ret) => {
        this.message.success(`Hell yeah! ${ret.length} Guilds are locked into the database.`);
      },
      error: (error) => {
        this.message.error(`Oh no, something went wrong. Guilds were NOT initialised`);
        console.error(`Failed to successfully seedGuilds. Error: ${error.message}`, { nzDuration: 10000, nzPauseOnHover: true });
      },
      complete: () => {
        this.isLoadingGuilds = false;
      }
    });
  }

  public providersTransfer() {
    this.isUpdatingProvidersTransfer = true;
    this.userActivitiesService.fixAllProviderOnActivities().subscribe({
      next: (_ret) => {
        this.message.success('Giddyup! We successfully migrated your providers.');
      },
      error: (error) => {
        console.error(`Failed to successfully seedDatabase for providers. Error: ${error.message}`, { nzDuration: 10000, nzPauseOnHover: true });
      },
      complete: () => {
        this.isUpdatingProvidersTransfer = false;
      },
    });
  }

  public updateTracks() {
    this.isLoadingTracks = true;
    this.trackService.updateTracks().subscribe({
      next: (ret) => {
        console.log(`UpdateTracks returned with ${ret}`);
        this.message.create('success', 'Hey hey hey! Tracks have been laid.');
      },
      error: (error) => {
        console.error(`Failed to successfully UpdateTracks. Error: ${error.message}`, { nzDuration: 10000, nzPauseOnHover: true });
      },
      complete: () => {
        this.isLoadingTracks = false;
      }
    });
  }

  public regenerateTrackDescriptions() {
    this.isLoadingGenerateTrackDescriptions = true;
    this.message.create('warning', 'Grab a snack. This will take a while.');
    this.trackService.regenerateTrackDescriptions().subscribe({
      next: (ret) => {
        console.log(`generateTrackDescriptions returned with ${ret}`);
        this.message.create('success', 'Hey hey hey! Track descriptions have been generated.');
      },
      error: (error) => {
        console.error(`Failed to successfully generateTrackDescriptions. Error: ${error.message}`, { nzDuration: 10000, nzPauseOnHover: true });
        this.message.error('Something broke when the track descriptions were being generated. Check the console.');
      },
      complete: () => {
        this.isLoadingGenerateTrackDescriptions = false;
      }
    });
  }

  public beforeUpload = (file: NzUploadFile): boolean => {
    if (!file?.size) {
      throw new Error('The file and the size must be known by this point');
    }

    if (this.uploadedFileValidSize(file.size)) {
      this.fileList = this.fileList.concat(file);
      return false;
    } else {
      this.message.create('error', 'Your file has to be less than 5MB');
    }
    return false;
  };

  public seedRecommendations(profession: EProfessionType | null = null) {
    let seedRecommendationsObeservable: Observable<string[]>;
    if (profession) {
      seedRecommendationsObeservable = this.recommendationService.seedRecommendations(profession);
    }
    else {
      const formData = new FormData();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.fileList.forEach((file: any) => {
        formData.append('fileKey', file, file.name);
      });

      seedRecommendationsObeservable = this.recommendationService.seedRecommendationsFromFile(formData);
    }

    this.isLoadingRecommendations = true;
    seedRecommendationsObeservable.subscribe({
      next: (_ret) => {
        for (const ret of _ret) {
          this.message.success(`Recommendations for ${ret} successful.`);
        }
      },
      error: (error) => {
        if (profession) {
          this.message.error(`Failed to seed activities for ${profession}.\nError: ${error.error}`, { nzDuration: 10000, nzPauseOnHover: true });
        } else {
          this.message.error(`Failed to seed activities for selected file.\nError: ${error.error}`, { nzDuration: 10000, nzPauseOnHover: true });
        }
      },
      complete: () => {
        this.isLoadingRecommendations = false;
      }
    });
  }

  public addMetadataToExisitingActivities() {
    this.isAddingMetadata = true;
    this.message.info('This will take a moment, another message will appear once complete');

    this.userActivitiesService.addMetadataToExisitingActivities().subscribe({
      next: (_res) => {
        this.message.success('Ka-ching! We\'ve got shiny new metadata for Activities');
      },
      error: (error) => {
        this.message.error(`Dammit! Failed to add metadata to Activities.\nError: ${error.message}`, { nzDuration: 10000, nzPauseOnHover: true });
      },
      complete: () => {
        this.isAddingMetadata = false;
      }
    }
    );
  }

  public updateSkillsCache() {
    this.isUpdatingSkillsCache = true;
    this.skillsReportService.updateSkillsCache().subscribe({
      next: (_ret) => {
        this.message.success('Cache Registers have been refilled!');
      },
      error: (error) => {
        this.message.error(`Failed to update Skills Cache.\nError: ${error.message}`, { nzDuration: 10000, nzPauseOnHover: true });
      },
      complete: () => {
        this.isUpdatingSkillsCache = false;
      }
    });
  }

  public updateUsernames() {
    this.isUpdatingUsernames = true;
    this.accountDetailsService.updateUsernames().subscribe({
      next: (_ret) => {
        this.message.success('Usernames have been fixed in the database.');
      },
      error: (error) => {
        this.message.error(`We failed to update the user names.\nError: ${error.message}`, { nzDuration: 10000, nzPauseOnHover: true });
      },
      complete: () => {
        this.isUpdatingUsernames = false;
      }
    });
  }

  public fixDuplicateTracks() {
    this.isFixDuplicateTracks = true;

    this.trackService.fixDuplicateTracks().subscribe({
      next: (duplicateTracks) => {
        if (duplicateTracks === 0) {
          this.message.success('No problems detected with tracks.');
        }
        else {
          Object.keys(duplicateTracks).forEach((key) => {
            this.message.warning(`Fixed ${duplicateTracks} tracks`);
          });
        }
      },
      error: (error) => {
        this.message.error(`Somethign went wrong when trying to fix duplicate tracks.\nError: ${error.message}`, { nzDuration: 10000, nzPauseOnHover: true });
      },
      complete: () => {
        this.isFixDuplicateTracks = false;
      }
    });
  }

  public detectTrackProblems() {
    this.isDetectingTrackProblems = true;
    this.trackService.detectTrackProblems().subscribe({
      next: (trackProblems) => {
        if (Object.keys(trackProblems).length === 0) {
          this.message.success('No problems detected with tracks.');
        }
        else {
          Object.keys(trackProblems).forEach((key) => {
            this.message.success(`${key} : ${trackProblems[key]}`);
          });
        }
      },
      error: (error) => {
        this.message.error(`Uh oh, we failed when trying to detect track problems.\nError: ${error.message}`, { nzDuration: 10000, nzPauseOnHover: true });
      },
      complete: () => {
        this.isDetectingTrackProblems = false;
      }
    });
  }

  public markCurrentGuildOwnersAsMembers() {
    this.isMarkingCurrentGuildOwnersAsMembers = true;
    this.guildService.markCurrentGuildOwnersAsMembers().subscribe({
      next: (res) => {
        if (res.length) {
          this.message.success(`Owners were marked as members for ${res.length} guilds`);
        } else {
          this.message.success(`All the guild owners are where they should be`);
        }
      },
      error: (error) => {
        this.message.error(`Uh oh, we failed when trying mark the current guild owners as members. Error: ${error.message}`);
      },
      complete: () => {
        this.isMarkingCurrentGuildOwnersAsMembers = false;
      }
    })
  }

  public cleanEmptySkills() {
    this.isCleaningEmptySkills = true;
    this.trackService.cleanTrackProblems('EmptySkills').subscribe({
      next: (cleanResults) => {
        this.message.success(cleanResults);
      },
      error: (error) => {
        this.message.error(`Uh oh, something's happened when Cleaning Tracks.\nError: ${error.message}`, { nzDuration: 10000, nzPauseOnHover: true });
      },
      complete: () => {
        this.isCleaningEmptySkills = false;
      }
    });
  }

  public cleanEmptyPlaylistActivities() {
    this.isCleaningEmptyPlaylistActivities = true;
    this.playlistService.cleanEmptyPlaylistActivities().subscribe({
      next: (cleanedPlaylists) => {
        if (!cleanedPlaylists.length) {
          this.message.success('No playlist activities that needed to be cleaned');
        } else {
          this.message.success(`We successfully cleaned ${cleanedPlaylists.length} empty activities.`);
        }
      },
      error: (error) => {
        this.message.error(`Oh no, something's happened. We ballezed up when we tried to clean empty playlist activities.\nError: ${error.message}`, { nzDuration: 10000, nzPauseOnHover: true });
      },
      complete: () => {
        this.isCleaningEmptyPlaylistActivities = false;
      }
    });
  }

  public cleanUntrimmedSkills() {
    this.isCleaningUntrimmedSkills = true;
    this.skillsService.cleanUntrimmedSkills().subscribe({
      next: (remainingSkills) => {
        if (!remainingSkills.length) {
          this.message.success('All the untrimmed skills have been sorted. Nice!');
        } else {
          this.message.warning(`There are ${remainingSkills.length} skills that needs to be sorted. Check track skills for usage and duplicates`);
        }
      },
      error: (error) => {
        this.message.error(`Uh oh, there's a problem cleaning up untrimmed skills.\nError: ${error.message}`, { nzDuration: 10000, nzPauseOnHover: true });
      },
      complete: () => {
        this.isCleaningUntrimmedSkills = false;
      }
    });
  }

  public toggleRole(user: IUserProfileSubscription, role: string) {
    let initial: boolean;

    if (role === 'admin') {
      initial = user.isAdmin;
      user.isAdmin = !user.isAdmin;
    } else if (role === 'test') {
      initial = user.isTest;
      user.isTest = !user.isTest;
    } else {
      throw new Error('Cannot set a role to a value other than the ones we support');
    }

    this.isLoadingRoles.set(user.id, true);

    this.accountDetailsService.updateBasicUserRole(user).subscribe({
      next: () => {
        this.initTestUserIndex();
      },
      error: (err) => {
        // need to put the values back again
        const userMatch = this.userProviders.find((up) => up.user.id === user.id);
        if (userMatch) {
          if (role === 'admin') {
            this.message.error(`Failed to toggleAdmin for ${userMatch.user.name} from ${initial} to ${user.isAdmin}.\nError: ${err.message}`, { nzDuration: 10000, nzPauseOnHover: true });
            userMatch.user.isAdmin = initial;
          } else if (role === 'test') {
            this.message.error(`Failed to toggleTest for ${userMatch.user.name} from ${initial} to ${user.isTest}.\nError: ${err.message}`, { nzDuration: 10000, nzPauseOnHover: true });
            userMatch.user.isTest = !user.isTest;
          }
        }
      },
      complete: () => {
        this.isLoadingRoles.set(user.id, false);
      }
    });
  }

  public toggleTrial(user: IUserProfileSubscription) {
    this.isLoadingTrial.set(user.id, true);

    user.isTrial = !user.isTrial;
    this.accountDetailsService.updateTrialStatus(user).subscribe({
      next: () => {
        const userMatch = this.userProviders.find((up) => up.user.id === user.id);
        if (userMatch) {
          userMatch.user.isTrial = user.isTrial;
        }
      },
      error: (error) => {
        this.message.error(`Failed to toggle trial for ${user.name}. Returned with error.\nError:${error}`, { nzDuration: 10000, nzPauseOnHover: true });
      },
      complete: () => {
        this.isLoadingTrial.set(user.id, false);
      }
    });
  }

  public toggleEnableDeleted() {
    this.includeDeletedUsers = !this.includeDeletedUsers;
    this.ngOnInit();
  }

  public showDeleteInput(user: IUserProfileSubscription): void {
    this.showDeletedInput.set(user.id, true);
    setTimeout(() => {
      const inputElement = this.deleteInputs.find((element) => element.nativeElement.id === `deleteInput-${user.id}`);
      if (inputElement) {
        inputElement.nativeElement.focus();
      }
    }, 0);
  }

  confirmDelete(user: IUserProfileSubscription): void {
    const deletedReason = this.deleteReasons.get(user.id);
    if (deletedReason && deletedReason.length <= 60) {
      // Proceed with delete logic
      this.toggleDeleted(user);
      this.deleteReasons.set(user.id, '');
    } else {
      // Handle error (e.g., show a message to the user)
    }
  }

  getDeleteReason(userId: string): string {
    return this.deleteReasons.get(userId) || '';
  }

  setDeleteReason(userId: string, reason: string): void {
    this.deleteReasons.set(userId, reason);
  }

  public toggleDeleted(user: IUserProfileSubscription) {
    const toDeleteWithNoReason = !user.isDeleted && !this.deleteReasons.get(user.id);
    if (toDeleteWithNoReason) {
      console.error('No reason provided for deleting user. This is required');
      return;
    }

    const deletedReason = this.deleteReasons.get(user.id) ?? '';

    const userToBeDeleted = !user.isDeleted;
    this.isLoadingDeleted.set(user.id, true);
    console.log(`Toggeling deleted = ${userToBeDeleted} for user with email ${user.email}`);
    this.accountDetailsService.deleteUser(user, userToBeDeleted, deletedReason).subscribe({
      next: (deletedUser) => {
        this.deleteReasons.set(user.id, '');
        this.showDeletedInput.set(user.id, false);
        user.isDeleted = deletedUser.deleted;
        if (!userToBeDeleted) {
          this.message.success(`Successfully un-deleted user: ${user.email}.`, { nzDuration: 10000, nzPauseOnHover: true });
        } else if (!deletedUser && userToBeDeleted) {
          this.message.warning(`Attempt to delete user: ${user.email} but that user was already identified as deleted`, { nzDuration: 10000, nzPauseOnHover: true });
        } else if (deletedUser.subscriptionStatus) {
          this.message.info(`Successfully ${user.isDeleted ? 'nuked' : 're-activate'} User ${user.email}. But, Subscription status = ${deletedUser.subscriptionStatus}. Jump on Stripe and make sure it's correct.`, { nzDuration: 10000, nzPauseOnHover: true });
        } else {
          this.message.success(`Successfully ${user.isDeleted ? 'nuked' : 're-activate'} ${user.email}.`);
        }
      },
      error: (error) => {
        const nukeString = user.isDeleted ? 'nuke' : 're-activate';
        this.message.error(`Failed to ${nukeString} user ${user.email}. Returned with error.\nError:${error}`, { nzDuration: 10000, nzPauseOnHover: true });
      },
      complete: () => {
        this.isLoadingDeleted.set(user.id, false);
      }
    });
  }

  public getProviderLoginName(providersSet: IProviderLoginName[], providerName: string): string {
    for (const provider of providersSet) {
      if (provider.providerName === providerName) {
        return provider.loginName;
      }
    }

    return '';
  }

  public getNotTestIndex(user: IUserProfileSubscription): string {
    const index = this.notTestUserIndex.get(user.id);
    if (!index) {
      return ' ';
    }

    return index.toString();
  }

  private uploadedFileValidSize(fileSize: number) {
    // 5MB max file size, measured in bytes
    return fileSize <= 5000000;
  }

  private initTestUserIndex() {
    this.notTestUserIndex = new Map<string, number>();
    let notTestCount = 0;

    this.userProviders.forEach((u) => {
      if (u.user.id !== '0' && !u.user.isTest) {
        notTestCount++;
        this.notTestUserIndex.set(u.user.id, notTestCount);
      }
    });
  }

  private getUsersLoginNamesForProviders(userProviderLoginNames: IUserProvider[]) {
    for (const userProvierLoginNames of userProviderLoginNames) {
      this.providersService.getLoginNamesForProviders(userProvierLoginNames.user).subscribe({
        next: (loginNames) => {
          if (!userProviderLoginNames) {
            throw new Error('userProviderLoginNames should be defined');
          }
          const providerSet = userProviderLoginNames.find((us) => us.user.id === userProvierLoginNames.user.id)?.providerSet;

          for (const provider of this.providers) {
            let loginNameForProvider = loginNames[provider.name];
            if (!loginNameForProvider) {
              loginNameForProvider = '';
            }
            providerSet?.push({
              providerName: provider.name,
              loginName: loginNameForProvider,
            } as IProviderLoginName);
          }
        },
        error: (err) => {
          this.message.error(`Failed to get user login names for user ${userProviderLoginNames} Error: ${err.message}`, { nzDuration: 10000, nzPauseOnHover: true });
        }
      });
    }
  }


  private updateProviders() {
    this.providersService.getProviders().subscribe({
      next: (res) => {
        this.providers = res;
        this.getUsersLoginNamesForProviders(this.userProviders);
      }
    });
  }

  private compareIUserProvider(a: IUserProvider, b: IUserProvider) {
    if (a.isAdminProtected) {
      return -1;
    }

    if (a.user.name > b.user.name) {
      return 1;
    }
    return 0;
  }
}
