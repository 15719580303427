import { ActivatedRoute, Router } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UntypedFormBuilder, Validators } from '@angular/forms';

// Libraries
import { NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { NzMessageService } from 'ng-zorro-antd/message';

// services
import { AccountDetailsService } from '../../../../services/account-details.service';
import { PublicProfileService } from 'src/app/services/public-profile.service';
import { RoutingStateService } from '../../../../services/routing-state.service';

// models
import { IUserProfile } from '../../../../models/user-profile.model';
import { StringValidationPipe } from 'src/app/generic-pipes/string-validation.pipe';

export interface UserEmailAddress {
  id: number,
  email: string,
  isPrimary: boolean,
  isVerified: boolean,
  isVerifying: boolean,
  applicationUserId: string
}

@Component({
  selector: 'sl-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['account-details.component.less'],
  providers: [StringValidationPipe]
})
export class AccountDetailsComponent implements OnInit {
  hasLoaded = false;
  userProfile!: IUserProfile;
  formValues!: UntypedFormGroup;
  salaryValue: number | undefined = 0;
  salaryIsValid = false;
  avatarUrl!: string;
  isLoading = false;
  isAccountDetailsLoading = false;
  isProfilePictureLoading = false;
  hostName!: string;
  disablePublicUrlInput = false;
  urlTaken!: boolean;
  alteredUrl = '';
  emailMap = new Map<string, UserEmailAddress>();
  readonly maxEmailCharLength = 320;

  constructor(
    private accountDetailsService: AccountDetailsService,
    private notificationMessage: NzMessageService,
    private fb: UntypedFormBuilder,
    private clipboard: Clipboard,
    private router: Router,
    private activitatedRoute: ActivatedRoute,
    private routingStateService: RoutingStateService,
    private publicProfileService: PublicProfileService,
    private stringValidationPipe: StringValidationPipe
  ) { }

  get previousRoute(): string | undefined {
    const paramFrom = this.activitatedRoute.snapshot.paramMap.get('from');
    return paramFrom ? paramFrom : undefined;
  }

  ngOnInit(): void {
    this.formValues = this.fb.group({
      name: ['', [Validators.required, this.firstNameCharacterValidator]],
      surname: ['', [Validators.required, this.surameCharacterValidator]],
      username: ['', [this.usernameValidator, this.userNameCharacterValidator, Validators.required]],
      email: [{ value: '', disabled: false }, [Validators.required]],
      additionalEmails: this.fb.array([]),
      address: [''],
      addressSuburb: [''],
      addressState: [''],
      addressPostcode: ['', [Validators.required]],
      salary: [0, [this.salaryValidator]],
      tagline: ['', [this.taglineValidator]],
      gender: [''],
      publicUrl: ['', [this.urlValidator, Validators.required]],
      privateProfile: [null],
    });
    this.loadAccount();
    this.loadProfilePicture();
    this.hostName = this.routingStateService.getHostName();
    this.validateFormFields(this.formValues);
  }

  validateFormFields(formGroup: UntypedFormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  get emailsFormArray(): FormArray {
    return this.formValues.get('additionalEmails') as FormArray;
  }

  addEmailAddress(email: string = '', isVerified: boolean = false) {
    this.emailsFormArray.push(this.fb.group({ id: 0, email: [email, Validators.email], isPrimary: [false], isVerified: [isVerified], isVerifying: [false], applicationUserId: [''] }));
  }

  removeEmailAddress(index: number) {
    if (this.emailsFormArray.controls.length === 1) {
      this.addEmailAddress();
    }
    this.emailsFormArray.removeAt(index);
  }

  updateValidators(index: number) {
    const emailsArray = this.formValues.get('additionalEmails') as FormArray;
    const emailControl = emailsArray.at(index).get('email');
    const selectedUserEmailAddress = emailsArray.at(index) as FormGroup;
    const selectedUserEmailAddressControl = selectedUserEmailAddress.get('email');
    const originalEmailAddressValue = this.emailMap.get(selectedUserEmailAddressControl!.value);

    // Update the validators for the email control
    emailControl!.setValidators([Validators.email]);
    emailControl!.updateValueAndValidity();

    // If the email address doesn't match the one that was stored at the index initially, it means that the user has enetered a different one and it needs to be checked and verified again
    if (!originalEmailAddressValue || !originalEmailAddressValue.isVerified) {
      this.emailsFormArray.at(index).patchValue({
        id: 0,
        email: selectedUserEmailAddressControl!.value,
        isPrimary: false,
        isVerified: false,
        isVerifying: false,
        applicationUserId: '',
      });

      // Otherwise the user has re-entered the previously verified email and should not have to verify the email again
    } else {
      this.emailsFormArray.at(index).patchValue({
        id: originalEmailAddressValue.id,
        email: selectedUserEmailAddressControl!.value,
        isPrimary: originalEmailAddressValue.isPrimary,
        isVerified: originalEmailAddressValue.isVerified,
        isVerifying: originalEmailAddressValue.isVerifying,
        applicationUserId: originalEmailAddressValue.applicationUserId,
      });
    }
  }

  allocateAsPrimaryEmailAddress(index: number) {
    const emailsArray = this.formValues.get('additionalEmails') as FormArray;
    const selectedUserEmailAddress = emailsArray.at(index) as FormGroup;
    const selectedUserEmailAddressControl = selectedUserEmailAddress.get('email');
    const isSelectedUserEmailVerified = selectedUserEmailAddress.get('isVerified');

    if (!selectedUserEmailAddressControl!.value) {
      this.notificationMessage.error("Email address cannot be empty");
    } else if (this.doesEmailHaveError(index)) {
      this.notificationMessage.error("Your email address must be valid to mark it as primary");
    } else if (!isSelectedUserEmailVerified!.value) {
      this.notificationMessage.error("Your email address must be verified to mark it as primary");
    } else {
      for (let i = 0; i < emailsArray.length; i++) {
        const emailGroup = emailsArray.at(i) as FormGroup;
        const isPrimaryControl = emailGroup.get('isPrimary');

        if (i === index) {
          const userEmail = this.formValues.get('email')!;
          isPrimaryControl!.setValue(true);
          this.addEmailAddress(userEmail.value, true);
          userEmail.setValue(selectedUserEmailAddressControl!.value);
          this.removeEmailAddress(i);
          this.initialiseEmailAddressMap(emailsArray.value);
        } else {
          isPrimaryControl!.setValue(false);
        }
      }
      this.sortEmailAddresses(emailsArray);
      this.notificationMessage.success(`${selectedUserEmailAddressControl!.value} has been set as the primary email address`);
    }
  }

  isPrimaryEmailAddress(index: number): boolean {
    const emailsArray = this.formValues.get('additionalEmails') as FormArray;
    const selectedUserEmailAddress = emailsArray.at(index) as FormGroup;
    const selectedUserEmailAddressControl = selectedUserEmailAddress.get('email');
    const isSelectedUserEmailAddressPrimary = selectedUserEmailAddress.get('isPrimary');

    if (selectedUserEmailAddressControl!.value) {
      if (isSelectedUserEmailAddressPrimary!.value) {
        return true;
      }
    }
    return false;
  }

  isEmailVerified(index: number): boolean {
    const emailsArray = this.formValues.get('additionalEmails') as FormArray;
    const selectedUserEmailAddress = emailsArray.at(index) as FormGroup;
    const isSelectedUserEmailAddressVerified = selectedUserEmailAddress.get('isVerified');

    if (isSelectedUserEmailAddressVerified!.value) {
      return true;
    }
    return false;
  }

  isEmailBeingVerified(index: number): boolean {
    const emailsArray = this.formValues.get('additionalEmails') as FormArray;
    const selectedUserEmailAddress = emailsArray.at(index) as FormGroup;
    const isSelectedUserEmailAddressBeingVerified = selectedUserEmailAddress.get('isVerifying');

    if (isSelectedUserEmailAddressBeingVerified!.value) {
      return true;
    }
    return false;
  }

  doesEmailHaveError(index: number): boolean {
    const emailArray = this.formValues.get('additionalEmails') as FormArray;
    const emailGroup = emailArray.at(index) as FormGroup;
    return emailGroup.get('email')!.hasError('email');
  }

  doesEmailConatainValue(index: number): boolean {
    const emailArray = this.formValues.get('additionalEmails') as FormArray;
    const emailGroup = emailArray.at(index) as FormGroup;
    return emailGroup.get('email')!.value;
  }

  verifyEmailAddress(index: number) {
    const emailsArray = this.formValues.get('additionalEmails') as FormArray;
    const selectedUserEmailAddress = emailsArray.at(index) as FormGroup;
    const selectedUserEmailAddressControl = selectedUserEmailAddress.get('email');
    const isSelectedUserEmailAddressBeingVerified = selectedUserEmailAddress.get('isVerifying');

    if (selectedUserEmailAddressControl?.value) {
      this.accountDetailsService.isEmailAddressTaken(selectedUserEmailAddressControl.value).subscribe({
        next: (res) => {
          if (res === true) {
            this.notificationMessage.error("A SeaLadder account is already associated with this email. Please use another one.");
          } else {
            if (this.emailAddressExists()) {
              this.notificationMessage.error("Duplicate email addresses found");
            } else {
              isSelectedUserEmailAddressBeingVerified?.setValue(true);
              this.accountDetailsService.updateEmailAddresses(emailsArray.value).subscribe({
                next: (res) => {
                  this.initialiseEmailAddressMap(res);
                  const emailToVerify = res.find(emailAddress => emailAddress.email === selectedUserEmailAddressControl.value);
                  if (emailToVerify) {
                    this.accountDetailsService.verifyEmailAddress(emailToVerify.id).subscribe({
                      next: () => {
                        this.notificationMessage.success("Please check your inbox for the verification link.");
                      },
                      error: (error: Error) => {
                        isSelectedUserEmailAddressBeingVerified?.setValue(false);
                        console.error("We couldn't verify this email address", error);
                        this.notificationMessage.error("We couldn't verify this email address. Please try again later.");
                      }
                    })
                  }
                },
                error: () => {
                  isSelectedUserEmailAddressBeingVerified?.setValue(false);
                  this.notificationMessage.error("We couldn't verify this email address. Please try again later.");
                }
              })
            }
          }
        },
        error: (error: Error) => {
          console.error("We couldn't verify this email address", error);
          this.notificationMessage.error("We couldn't verify this email address. Please try again later.");
        }
      })
    } else {
      isSelectedUserEmailAddressBeingVerified?.setValue(false);
      this.notificationMessage.error("We couldn't verify this email address. Please try again later.");
    }
  }

  sortEmailAddresses(emailsArray: FormArray) {
    const emails = emailsArray.controls.sort((a, b) => {
      const aIsVerified = (a as FormGroup).get('isVerified')!.value;
      const bIsVerified = (b as FormGroup).get('isVerified')!.value;

      if (aIsVerified && !bIsVerified) {
        return -1;
      } else if (!aIsVerified && bIsVerified) {
        return 1;
      } else {
        return 0;
      }
    });

    const sortedEmails = emails.map(control => this.fb.group({
      id: control.get('id')!.value,
      email: control.get('email')!.value,
      isPrimary: control.get('isPrimary')!.value,
      isVerified: control.get('isVerified')!.value,
      isVerifying: control.get('isVerifying')!.value,
      applicationUserId: control.get('applicationUserId')!.value,
    }));

    this.formValues.setControl('additionalEmails', this.fb.array(sortedEmails));
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  uploadProfilePicture = (item: NzUploadXHRArgs) => {
    const fileToUpload = item.file;
    if (!fileToUpload) {
      throw new Error("Failed to get a valid file for the profile picture");
    }
    const fileType = fileToUpload.type ? fileToUpload.type : '';
    const fileSize = fileToUpload.size ? fileToUpload.size : 0;

    if (this.uploadedFileValidType(fileType)) {
      if (this.uploadedFileValidSize(fileSize)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.accountDetailsService.uploadProfilePicture(fileToUpload as any, 'profile').subscribe({
          next: () => {
            this.notificationMessage.create('success', 'Your image has been uploaded successfully');
            const reader = new FileReader();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            reader.readAsDataURL(fileToUpload as any);
            reader.onload = () => {
              if (!reader.result) {
                throw new Error('result must be defined at this point');
              }

              this.avatarUrl = reader.result?.toString();
            };
          },
          error: () => {
            this.notificationMessage.create('error', 'Error uploading file, try again');
          }
        });
      } else {
        this.notificationMessage.create('error', 'Your image has to be less than 5MB');
      }
    } else {
      this.notificationMessage.create('error', 'Your image has to be jpg/jpeg or png');
    }
  };

  loadProfilePicture(): void {
    this.isProfilePictureLoading = true;
    this.accountDetailsService.getImage('profile').subscribe(
      (base64Text) => {
        this.avatarUrl = base64Text;
        this.isProfilePictureLoading = false;
      },
      () => {
        this.notificationMessage.create('error', 'Error uploading profile picture, try again');
        this.isProfilePictureLoading = false;
      }
    );
  }

  uploadedFileValidType(fileType: string): boolean {
    return fileType === 'image/jpeg' || fileType === 'image/png';
  }

  uploadedFileValidSize(fileSize: number): boolean {
    // 5MB max file size, measured in bytes
    return fileSize <= 5000000;
  }

  copyUrl(inputElement: HTMLInputElement): void {
    this.clipboard.copy(this.hostName + '/profile/' + inputElement.value);
    this.notificationMessage.success('Profile URL copied to clipboard');
  }

  compareUrlValue(alteredUrl: string): void {
    if (alteredUrl !== '') {
      if (alteredUrl !== this.userProfile.publicUrl) {
        this.checkUrlAvailability(alteredUrl);
      } else {
        this.urlTaken = false;
      }
    }
  }

  checkUrlAvailability(alteredUrl: string) {
    this.publicProfileService.checkUrlAvailability(alteredUrl).subscribe(
      (ret) => {
        if (false === ret) {
          this.notificationMessage.success(`The Username "${alteredUrl}" is available.  Click 'Update Account' to accept`);
          this.urlTaken = false;
        } else {
          this.notificationMessage.warning(`The Username "${alteredUrl}" is taken, Please enter a new custom Public URL`);
          this.urlTaken = true;
        }
      },
      () => {
        this.notificationMessage.error('Error checking entered custom URL. Please try again.');
      }
    );
  }

  updatePublicProfileStatus(privateStatus: boolean): void {
    this.formValues.controls.privateProfile.setValue(privateStatus);
    this.disablePublicUrlInput = privateStatus;
    this.accountDetailsService.privateStatusUpdate(privateStatus).subscribe(
      (ret) => {
        if (true === ret) {
          this.notificationMessage.success('You have now set your profile to private');
        } else {
          this.notificationMessage.success('You have now set your profile to public');
        }
      },
      () => {
        this.notificationMessage.error('Error changing the privacy status of user. Please try again.');
      }
    );
  }

  update(): void {
    const formvalues: IUserProfile = this.formValues.value;
    const additionalEmailsControl = this.formValues.get('additionalEmails') as FormArray;
    let hasInvalidEmail = false;

    for (let i = 0; i < additionalEmailsControl.length; i++) {
      if (this.doesEmailHaveError(i)) {
        hasInvalidEmail = true;
        break;
      }
    }

    if (hasInvalidEmail) {
      this.notificationMessage.error("Your email address must be valid");
    } else if (this.emailAddressExists()) {
      this.notificationMessage.error("Duplicate email addresses found");
    } else {
      this.updateSalaryForm();
      formvalues.salary = this.salaryValue ? this.salaryValue : 0;
      this.accountDetailsService.update(formvalues).subscribe({
        next: (ret) => {
          this.userProfile = ret;
          this.initialiseEmailAddressMap(ret.additionalEmails);
          this.buttonLoading();
          this.profileRedirect();
          setTimeout(() => {
            this.notificationMessage.success('Update successful');
          }, 750);
        },
        error: () => {
          this.buttonLoading();
          setTimeout(() => {
            this.notificationMessage.error('Error updating your account');
          }, 750);
        }
      });
    }
  }

  emailAddressExists(): boolean {
    const formvalues: IUserProfile = this.formValues.value;
    return formvalues.additionalEmails.some((email, index, self) =>
      email.email.trim() !== '' &&
      self.findIndex(e => e.email.trim() === email.email.trim()) !== index) ||
      formvalues.additionalEmails.some(e => e.email.trim() === formvalues.email.trim());
  }

  profileRedirect(): void {
    if (this.previousRoute) {
      setTimeout(() => {
        if (this.previousRoute) {
          this.router.navigateByUrl(this.previousRoute);
        }
      }, 750);
    }
  }

  disableUpdateButton(): boolean {
    const disabled = this.formValues.invalid || this.urlTaken === true;
    return disabled;
  }

  public validSalary(salaryFromForm: string): number | undefined {
    if (!salaryFromForm) {
      return undefined;
    }

    let inputSalary = salaryFromForm;
    const hasThousands = /[kK]+$/.test(inputSalary);
    inputSalary = inputSalary.replace(/[$a-zA-Z,_-]/g, '');
    let inputSalaryValue = parseInt(inputSalary, 10);

    // Convert K to 1000
    if (hasThousands) {
      if (!isNaN(inputSalaryValue)) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        inputSalaryValue *= 1000;
      }
    }

    // if the value is STILL not a number, then return undefined
    if (!isNaN(inputSalaryValue)) {
      this.salaryIsValid = true;
      return inputSalaryValue;
    } else {
      this.salaryIsValid = false;
      return undefined;
    }
  }

  private buttonLoading(): void {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 750);
  }

  private loadAccount(): void {
    this.isAccountDetailsLoading = true;
    this.accountDetailsService.loadCurrentUser().subscribe({
      next: (ret) => {
        this.hasLoaded = true;
        this.userProfile = ret;
        this.emailsFormArray.clear();

        if (!ret.additionalEmails.length) {
          this.addEmailAddress();
        } else {
          ret.additionalEmails.forEach(email => {
            this.emailsFormArray.push(this.fb.group({
              id: [email.id],
              email: [email.email, Validators.email],
              isPrimary: [email.isPrimary],
              isVerified: [email.isVerified],
              isVerifying: [false],
              applicationUserId: [email.applicationUserId]
            }));

            this.initialiseEmailAddressMap(ret.additionalEmails);
          });
        }

        this.formValues.patchValue(ret);
        this.sortEmailAddresses(this.emailsFormArray);
        this.disablePublicUrlInput = this.userProfile.privateProfile;
        this.isAccountDetailsLoading = false;
      },
      error: () => {
        this.notificationMessage.create('error', 'Error uploading account details, try again');
        this.isAccountDetailsLoading = false;
      }
    });
  }

  private initialiseEmailAddressMap(emailAddresses: UserEmailAddress[]) {
    // Store the email object in a map to tell whether or not they've been updated
    emailAddresses.forEach(emailAddress => {
      this.emailMap.set(emailAddress.email, emailAddress);
    })
  }

  private updateSalaryForm(): void {
    if (this.formValues.controls.salary.value !== this.userProfile.salary) {
      const validValue = this.validSalary(this.formValues.controls.salary.value);

      if (undefined !== validValue) {
        this.salaryValue = validValue;

        if (this.salaryValue) {
          this.formValues.controls.salary.setValue(this.salaryValue.toString());
        }
      } else {
        this.formValues.controls.salary.setValue(this.userProfile.salary);
        this.salaryValue = this.userProfile.salary;
      }
    }
  }

  private salaryValidator = (control: UntypedFormControl): { [s: string]: boolean } | undefined => {
    if (typeof control.value && control.value === 'number') {
      return { salaryNotValid: false };
    }
    if (control.value && typeof control.value === 'string') {
      this.validSalary(control.value);
      return !this.salaryIsValid ? { salaryNotValid: true } : undefined;
    }

    return undefined;
  };

  private taglineValidator = (control: UntypedFormControl): { [s: string]: boolean } | undefined => {
    if (control.value) {
      if (control.value.length > 280) {
        return { taglineNotValid: true };
      }
    }

    return undefined;
  };

  private urlValidator = (control: UntypedFormControl): { [s: string]: boolean } | undefined => {
    if (control.value) {
      if (control.value.length > 59) {
        return { urlTooLong: true };
      }

      if (/ /g.test(control.value)) {
        return { urlHasSpaces: true };
      }

      if (/[^a-zA-Z0-9_-]/g.test(control.value)) {
        return { urlInvalidCharacters: true };
      }
    }

    return undefined;
  };

  private usernameValidator = (control: UntypedFormControl): { [s: string]: boolean } | undefined => {
    if (control.value) {
      if (control.value.length > this.maxEmailCharLength) {
        return { usernameTooLong: true };
      }

      if (/ /g.test(control.value)) {
        return { usernameHasSpaces: true };
      }
    }

    return undefined;
  };

  private userNameCharacterValidator = (control: UntypedFormControl): { [s: string]: string } | undefined => {
    const transformedValue = this.stringValidationPipe.transform(control.value);
    if (transformedValue) {
      return { invalidName: transformedValue };
    }
    return undefined;
  }

  private firstNameCharacterValidator = (control: UntypedFormControl): { [s: string]: string } | undefined => {
    const transformedValue = this.stringValidationPipe.transform(control.value);
    if (transformedValue) {
      return { invalidName: transformedValue };
    }
    return undefined;
  }

  private surameCharacterValidator = (control: UntypedFormControl): { [s: string]: string } | undefined => {
    const transformedValue = this.stringValidationPipe.transform(control.value);
    if (transformedValue) {
      return { invalidName: transformedValue };
    }
    return undefined;
  }
}
