// modules
import { GenericComponentsModule } from './../generic-components/generic-components.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgModule } from '@angular/core';
import { LocalStorageDirective } from 'ngx-localstorage';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { RouterModule, Routes } from '@angular/router';

// components
import { CreateGuildComponent } from './components/create-guild/create-guild.component';
import { GuildCardComponent } from './components/guild-card/guild-card.component';
import { GuildsComponent } from './components/guilds/guilds.component';
import { GuildHomeComponent } from './components/guild-home/guild-home.component';
import { MyGuildsComponent } from './components/my-guilds/my-guilds.component';
import { SearchGuildsComponent } from './components/search-guilds/search-guilds.component';

// services
import { AuthGuard } from '../services/auth.guard';
import { HasGoalGuard } from '../services/has-goal.guard';
import { GuildTopActivitiesComponent } from './components/guild-top-activities/guild-top-activities.component';
import { SharedPlaylistComponent } from './components/shared-playlist/shared-playlist.component';
import { GuildDetailsComponent } from './components/guild-details/guild-details.component';
import { SharedPlaylistCardComponent } from './components/shared-playlist/shared-playlist-card/shared-playlist-card.component';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';

const routes: Routes = [
  {
    path: 'guilds',
    component: GuildsComponent,
    canActivate: [HasGoalGuard, AuthGuard],
    children: [
      { path: 'create', component: CreateGuildComponent },
      {
        path: ':guildName/from/:userUrl/:inviteCode',
        component: GuildsComponent,
      },
      {
        path: ':guildName/from/:userUrl',
        component: GuildsComponent,
      },
      {
        path: 'home/:guildName',
        component: GuildHomeComponent,
        children: [
          { path: '', redirectTo: 'about', pathMatch: 'full' },
          { path: 'about', component: GuildDetailsComponent },
          { path: 'activities', component: GuildTopActivitiesComponent },
          { path: 'playlists', component: SharedPlaylistComponent },
        ],
      },
    ],
  },
];
@NgModule({
  declarations: [
    CreateGuildComponent, // declarations
    GuildCardComponent,
    GuildsComponent,
    GuildHomeComponent,
    MyGuildsComponent,
    SearchGuildsComponent,
    GuildTopActivitiesComponent,
    SharedPlaylistComponent,
    GuildDetailsComponent,
    SharedPlaylistCardComponent,
    SearchResultsComponent,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    GenericComponentsModule,
    InfiniteScrollModule,
    LocalStorageDirective,
    NzAlertModule,
    NzAutocompleteModule,
    NzAvatarModule,
    NzBadgeModule,
    NzButtonModule,
    NzCardModule,
    NzCheckboxModule,
    NzCollapseModule,
    NzDividerModule,
    NzDrawerModule,
    NzDropDownModule,
    NzEmptyModule,
    NzFormModule,
    NzGridModule,
    NzIconModule,
    NzImageModule,
    NzInputModule,
    NzInputNumberModule,
    NzLayoutModule,
    NzMenuModule,
    NzMessageModule,
    NzModalModule,
    NzPaginationModule,
    NzPopconfirmModule,
    NzRadioModule,
    NzSelectModule,
    NzSkeletonModule,
    NzSpinModule,
    NzStepsModule,
    NzSwitchModule,
    NzTableModule,
    NzTabsModule,
    NzTagModule,
    NzToolTipModule,
    NzUploadModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, { enableTracing: true })
  ],
  exports: [
    CreateGuildComponent, // exports
    GuildCardComponent,
    GuildsComponent,
    GuildHomeComponent,
    MyGuildsComponent,
    SearchGuildsComponent,
    GuildTopActivitiesComponent,
    SharedPlaylistComponent,
    GuildDetailsComponent,
    SharedPlaylistCardComponent,
    SearchResultsComponent
  ],
})
export class GuildsModule { }
