<div class="modal-component">
  <div class="content-wrapper profile-v2-scheme" [ngClass]="{ 'edit-mode-wrapper': editActivityMode }">
    <div class="left-col">
      <h3 id="modal-header" *ngIf="editActivityMode && activityKind === ActivityKind.UserActivity">Update the details of the activity below:</h3>
      <h3 id="modal-header" *ngIf="!editActivityMode && activityKind === ActivityKind.UserActivity">Enter the details of the activity below:</h3>
      <h3 id="modal-header" *ngIf="activityKind === ActivityKind.UserAction">Update the details of the action below:</h3>
      <br />

      <a class="red">* </a><label class="control-label">Select Goal:</label>
      <nz-select [ngClass]="{'bottom-margin': !noMatchingGoals, 'invalid-goal': selectedGoal?.value.deleted}" [nzStatus]="selectedGoal?.value.deleted || !selectedGoal ? 'error' : null" class="ngZorroSelector select-goal" [(ngModel)]="selectedGoal" name="selectedGoal"
        (ngModelChange)="getRelatedGoalSkills(); saveGoal()" [nzDisabled]="isActivityRecommendationAdded">
        <nz-option id="activity-goal-option" *ngFor="let option of goalOptions" [nzLabel]="option.label + (option.value.deleted ? ' (Deleted)' : '')" [nzValue]="option" [nzDisabled]="option.disabled"> </nz-option>
      </nz-select>

      <div *ngIf="noMatchingGoals" class="no-matching-goal-alert bottom-margin">
        <nz-alert nzType="warning" nzCloseable nzMessage="Warning: You haven't created any goals that are a good match for this playlist.">
        </nz-alert>
        <span *ngIf="recommendation.industry && recommendation.profession && recommendation.specialisation" class="goal-creator-link">
          <a id="goal-creator" nzType="link" [routerLink]="['/profile/overview']" [queryParams]="{activityId: recommendation.id, goalId: activityGoalId, industry: recommendation.industry, profession: recommendation.profession, specialisation: recommendation.specialisation}">
            Can't find a suitable goal? Why not create one of your own.
          </a>
        </span>
        <span *ngIf="!recommendation.industry || !recommendation.profession || !recommendation.specialisation" class="goal-creator-link">
          <a id="goal-creator" nzType="link" [routerLink]="['/profile/overview']" [queryParams]="{activityId: recommendation.id, goalId: activityGoalId}">
            Can't find a suitable goal? Why not create one of your own.
          </a>
        </span>
      </div>

      <label *ngIf="activityKind === ActivityKind.UserActivity" for="format" class="control-label"><a class="red">*</a>
        URL:
        <sl-help-info title="Activity Link" description="Your activity should have a link so that other people can find it. 
          For example, if it was a book you read, put a link to that book from Amazon." timeoutSec="10">
        </sl-help-info>
      </label>

      <input #activityUrl *ngIf="activityKind === ActivityKind.UserActivity" id="activity-url" nz-input class="bottom-margin" placeholder="URL" [(ngModel)]="editActivityMode ? selectedActivity?.link : selectedActivityUrl" (blur)="getMetaData()"
        [disabled]="editActivityMode ||  isActivityRecommendation || isActivityRecommendationAdded" (click)="activityUrl.select()" />

      <nz-alert *ngIf="!hasActivity && hasActivityDirty" [nzType]="'error'" [nzMessage]="'You haven\'t entered a valid URL'" [nzShowIcon]="'true'"> </nz-alert>

      <div *ngIf="loadingMetadata && activityKind === ActivityKind.UserActivity" class="loading-card">
        <ng-template #indicatorTemplate><i nz-icon nzType="loading"></i></ng-template>
        <nz-spin class="center" nzSimple [nzIndicator]="indicatorTemplate"> </nz-spin>
      </div>

      <div class="activity-title" *ngIf="!loadingMetadata">
        <label class="control-label"><a class="red">*</a>Title:</label>
        <input nz-input class="bottom-margin" placeholder="Title" [(ngModel)]="selectedActivityTitle" (ngModelChange)="handleActivityTitleChange()" [disabled]="isActivityRecommendationAdded" />
        <nz-alert *ngIf="!hasActivityTitle && hasActivityTitleDirty" [nzType]="'error'" [nzMessage]="'The title of the activity is somewhat important.'" [nzShowIcon]="'true'"> </nz-alert>
      </div>

      <a *ngIf="activityKind === ActivityKind.UserActivity" [href]="editActivityMode ? selectedActivity?.link : selectedActivityUrl" target="_blank" rel="noopener noreferrer">
        <nz-card *ngIf="urlMetadata !== null" class="url-card" nzHoverable [nzCover]="coverTemplate">
          <nz-card-meta [nzTitle]="urlMetadata.title" [nzDescription]="urlMetadata.description"> </nz-card-meta>
        </nz-card>
      </a>

      <ng-template #coverTemplate>
        <img class="metadata-image" [src]="loadingMetadata ? null : urlMetadata.imageUrl" />
      </ng-template>

      <label *ngIf="activityKind === ActivityKind.UserActivity" class="control-label"><a class="red">*</a>Activity Type:</label>

      <nz-select id="activity-kind" *ngIf="activityKind === ActivityKind.UserActivity" class="ngZorroSelector learning-type bottom-margin" nzShowSearch nzAllowClear nzPlaceHolder="Select an activity type" [(ngModel)]="selectedActivityType"
        [nzDisabled]="editActivityMode || isAYouTubePlaylist || isActivityRecommendationAdded" (ngModelChange)="handleActivityTypeChange()">
        <nz-option *ngFor="let option of learningTypeOptions" [nzLabel]="option" [nzValue]="option"></nz-option>
      </nz-select>
      <nz-alert *ngIf="!hasActivityType && hasActivityTypeDirty" [nzType]="'error'" [nzMessage]="'You haven\'t selected an activity type'" [nzShowIcon]="'true'"></nz-alert>

      <label *ngIf="activityKind === ActivityKind.UserActivity" class="control-label title-skills">Related Skills: </label>

      <nz-select id="related-skills" *ngIf="activityKind === ActivityKind.UserActivity" [(ngModel)]="selectedActivitySkillIds" tabindex="3" nzAllowClear nzShowSearch [nzDropdownRender]="renderTemplate" nzMode="multiple" class="ngZorroSelector bottom-margin"
        [nzTokenSeparators]="[',']" nzAllowClear [nzPlaceHolder]="'Add skills related to this activity'" [nzDisabled]="isAYouTubePlaylist || isActivityRecommendationAdded">
        <nz-option-group [nzLabel]="'New Skills'">
          <ng-container *ngFor="let option of skillOptions">
            <nz-option *ngIf="option.group === 'new'" class="skill-select-option" [nzLabel]="option.category + ': ' + option.name" [nzValue]="option.id" [nzHide]="!isNotSelected(option)"></nz-option>
          </ng-container>
        </nz-option-group>
        <nz-option-group [nzLabel]="'Suggested'">
          <ng-container *ngFor="let option of skillOptions">
            <nz-option *ngIf="option.group === 'suggested'" class="skill-select-option" [nzLabel]="option.category + ': ' + option.name" [nzValue]="option.id" [nzHide]="!isNotSelected(option)"></nz-option>
          </ng-container>
        </nz-option-group>
        <nz-option-group [nzLabel]="'Skills from activity'">
          <ng-container *ngFor="let option of skillOptions">
            <nz-option *ngIf="option.group === 'activity'" class="skill-select-option" [nzLabel]="option.category + ': ' + option.name" [nzValue]="option.id" [nzHide]="!isNotSelected(option)"></nz-option>
          </ng-container>
        </nz-option-group>
        <nz-option-group [nzLabel]="'Skills from goal'">
          <ng-container *ngFor="let option of skillOptions">
            <nz-option *ngIf="option.group === 'goal'" class="skill-select-option" [nzLabel]="option.category + ': ' + option.name" [nzValue]="option.id" [nzHide]="!isNotSelected(option)"></nz-option>
          </ng-container>
        </nz-option-group>
        <nz-option-group [nzLabel]="'All Skills'">
          <ng-container *ngFor="let option of skillOptions">
            <nz-option *ngIf="option.group === 'all'" class="skill-select-option" [nzLabel]="option.category + ': ' + option.name" [nzValue]="option.id" [nzHide]="!isNotSelected(option)"></nz-option>
          </ng-container>
        </nz-option-group>
      </nz-select>
      <ng-template #renderTemplate>
        <nz-divider></nz-divider>
        <div id="enable-all-skills" *ngIf="!enableAllSkills">
          <i nz-icon nzType="expand" nzTheme="outline"></i>
          <a id="add-new-skill" #addNewSkillsButton (click)="enableAddSkills()"> All Skills... </a>
        </div>
        <div class="container" *ngIf="enableAllSkills">
          <input id="new-skill-input" style="margin-bottom: 0px" [ngClass]="{ 'bottom-margin': hasActivity }" placeholder="Skill" type="text" nz-input #inputSkill tabindex="1" (keydown.enter)="$event.stopPropagation()" (keyup.enter)="addSkillButton.click()"
            (keydown.Tab)="$event.stopPropagation()" />
          <input id="new-category-input" style="margin-bottom: 0px" [ngClass]="{ 'bottom-margin': hasActivity }" placeholder="Category" type="text" nz-input #inputCategory tabindex="2" (keydown.enter)="$event.stopPropagation()" (keyup.enter)="addSkillButton.click()"
            (keydown.Tab)="$event.stopPropagation()" />
          <a class="add-item" #addSkillButton (click)="addNewSkillElement(inputCategory, inputSkill)">
            <!-- <div id="pop-confirm-skill" nz-popconfirm nzPopconfirmTitle="Do you also want to add this skill your selected goal?  [Goal: {{selectedGoal.label}}]" 
              [nzPopconfirmVisible]="showAddToGoalPopConfirm" (nzOnConfirm)="confirmAddSkillToGoal(inputCategory, inputSkill)" [nzCancelText]="'Nope'">
            </div> -->
            <!-- (nzOnOk)="confirmAddSkillToGoalManual(inputCategory, inputSkill)" -->
            <i nz-icon nzType="plus"></i> Add Skill
          </a>
        </div>
      </ng-template>

      <div *ngIf="!isAYouTubePlaylist">
        <label for="format" class="control-label title-due">Due Date:</label>
        <nz-radio-group id="data-radio-buttons" [(ngModel)]="dueDateMode" nzName="dueDateRadiogroup" [nzDisabled]="isActivityRecommendationAdded">
          <label nz-radio nzValue="duration">Duration</label>
          <label nz-radio nzValue="date">Date</label>
        </nz-radio-group>
        <div id="date-slider" *ngIf="dueDateMode === 'duration'; else dateMode">
          <nz-slider class="duration-mode" [ngClass]="{ 'duration-overdue': activityDuration < 0 }" [nzMarks]="durationMarks" [(ngModel)]="activityDuration" [nzStep]="null" [nzTooltipVisible]="'never'" (ngModelChange)="updateActivityDuration()"
            [nzDisabled]="isActivityRecommendationAdded"> </nz-slider>
        </div>
        <ng-template #dateMode>
          <nz-date-picker class="form-control input-sm bottom-margin date-mode" [(ngModel)]="activityDateScheduled" (ngModelChange)="changeTargetDate($event)" [nzDisabledDate]="disabledDates" [nzFormat]="'d MMM y'" [nzAllowClear]="false"> </nz-date-picker>
        </ng-template>

        <label id="immediate-start" *ngIf="!editActivityMode && hasSkills()" nz-checkbox [ngModel]="immediateStart()" (ngModelChange)="setUserOverrideImmediateStart($event)">Start Immediately</label>
        <br />
      </div>

      <nz-modal id="confirmation-modal" [(nzVisible)]="isUrlDuplicateModalVisible" nzTitle="Confirmation" nzOkText="Ok" nzCancelText="Cancel" (nzOnOk)="handleOkDuplicateConfirmation()" (nzOnCancel)="handleCancelDuplicateConfirmation()">
        <p id="confirmation-text" *nzModalContent>Hey, this activity already in your list, and you can't submit duplicate activities. Do you want to keep this for now?</p>
      </nz-modal>
    </div>

    <div *ngIf="editActivityMode && activityKind === ActivityKind.UserActivity" class="right-col">
      <div id="edit-note">
        <div id="editor" [froalaEditor]="editor" [(froalaModel)]="textModalText"></div>
      </div>
    </div>

    <div class="footer-submit">
      <div *ngIf="editActivityMode || activityKind === ActivityKind.UserAction" id="updateButton">
        <button id="update-backlog-btn" [disabled]="saveTextDisabled" nz-button nzType="primary" (click)="[updateActivity()]" [nzLoading]="isLoadingOne">
          <span id="update-btn-text">{{ saveTextDisabled ? noteLength - noteLimit + ' character(s) over the limit' : 'Update' }}</span>
        </button>
      </div>

      <div *ngIf="!editActivityMode && activityKind === ActivityKind.UserActivity" id="addButton">
        <div class="add-button-container">
          <nz-alert *ngIf="recommendation?.flaggedActivityType" class="flagged-activity-alert" nzType="info" [nzMessage]="flaggedActivityAlert" nzShowIcon></nz-alert>
          <ng-template #flaggedActivityAlert>
            <span>This activity has been flagged with: <b>"{{recommendation?.flaggedActivityType}}"</b>. Please double check before adding.</span>
          </ng-template>
          <button *ngIf="!isActivityRecommendationAdded" id="add-backlog-btn" nz-button nzType="primary" (click)="!isAYouTubePlaylist ? addBacklogItem() : addExternallyManagedPlaylist()" [nzLoading]="isLoadingOne" [disabled]="isAddButtonDisabled" nz-tooltip
            [nzTooltipTitle]="isAddButtonDisabledText">
            <span>Add</span>
          </button>
          <div *ngIf="isActivityRecommendationAdded" id="add-activity-complete">
            <span nz-typography nzType="success"><i nz-icon [nzType]="'check-circle'"></i></span> Activity added to your profile
          </div>
        </div>
      </div>
    </div>
  </div>

  <nz-modal [(nzVisible)]="showAddToGoalPopConfirm" nzTitle="Add skill to goal ({{ selectedGoal?.label }})" (nzOnOk)="confirmAddSkillToGoal()" (nzOnCancel)="cancelAddSkillToGoalManual()" [nzCancelText]="'Nope'" nzCentered>
    <ng-container *nzModalContent>
      <div class="add-to-goal-content">
        <img class="left-image" src="assets/img/icons/dart-board-colour.svg" />
        <div class="right-text">
          <p>This skill is not currently a part of your goal.</p>
          <p>Do you want to <b>also</b> add it to your selected goal?</p>
        </div>
      </div>
    </ng-container>
  </nz-modal>
</div>